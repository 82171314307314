import React from "react";

const LoaderAnim1 = () => {
  return (
    <div className='loader-palestine-map'>
      <svg
        width='108'
        height='301'
        viewBox='0 0 108 301'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_97_864)'>
          <path
            d='M106.085 3.81267C107.142 3.81267 108 2.95917 108 1.90633C108 0.853494 107.142 0 106.085 0C105.027 0 104.169 0.853494 104.169 1.90633C104.169 2.95917 105.027 3.81267 106.085 3.81267Z'
            fill='white'
          />
          <path
            d='M99.1288 10.7355C100.187 10.7355 101.044 9.88202 101.044 8.82919C101.044 7.77635 100.187 6.92285 99.1288 6.92285C98.071 6.92285 97.2134 7.77635 97.2134 8.82919C97.2134 9.88202 98.071 10.7355 99.1288 10.7355Z'
            fill='white'
          />
          <path
            d='M85.2508 17.6589C86.3086 17.6589 87.1661 16.8054 87.1661 15.7525C87.1661 14.6997 86.3086 13.8462 85.2508 13.8462C84.1929 13.8462 83.3354 14.6997 83.3354 15.7525C83.3354 16.8054 84.1929 17.6589 85.2508 17.6589Z'
            fill='white'
          />
          <path
            d='M92.2066 17.6589C93.2645 17.6589 94.122 16.8054 94.122 15.7525C94.122 14.6997 93.2645 13.8462 92.2066 13.8462C91.1488 13.8462 90.2913 14.6997 90.2913 15.7525C90.2913 16.8054 91.1488 17.6589 92.2066 17.6589Z'
            fill='white'
          />
          <path
            d='M99.1288 17.6589C100.187 17.6589 101.044 16.8054 101.044 15.7525C101.044 14.6997 100.187 13.8462 99.1288 13.8462C98.071 13.8462 97.2134 14.6997 97.2134 15.7525C97.2134 16.8054 98.071 17.6589 99.1288 17.6589Z'
            fill='white'
          />
          <path
            d='M106.085 17.6589C107.142 17.6589 108 16.8054 108 15.7525C108 14.6997 107.142 13.8462 106.085 13.8462C105.027 13.8462 104.169 14.6997 104.169 15.7525C104.169 16.8054 105.027 17.6589 106.085 17.6589Z'
            fill='white'
          />
          <path
            d='M85.2508 24.548C86.3086 24.548 87.1661 23.6945 87.1661 22.6417C87.1661 21.5888 86.3086 20.7354 85.2508 20.7354C84.1929 20.7354 83.3354 21.5888 83.3354 22.6417C83.3354 23.6945 84.1929 24.548 85.2508 24.548Z'
            fill='white'
          />
          <path
            d='M92.2066 24.548C93.2645 24.548 94.122 23.6945 94.122 22.6417C94.122 21.5888 93.2645 20.7354 92.2066 20.7354C91.1488 20.7354 90.2913 21.5888 90.2913 22.6417C90.2913 23.6945 91.1488 24.548 92.2066 24.548Z'
            fill='white'
          />
          <path
            d='M99.1288 24.548C100.187 24.548 101.044 23.6945 101.044 22.6417C101.044 21.5888 100.187 20.7354 99.1288 20.7354C98.071 20.7354 97.2134 21.5888 97.2134 22.6417C97.2134 23.6945 98.071 24.548 99.1288 24.548Z'
            fill='white'
          />
          <path
            d='M106.085 24.548C107.142 24.548 108 23.6945 108 22.6417C108 21.5888 107.142 20.7354 106.085 20.7354C105.027 20.7354 104.169 21.5888 104.169 22.6417C104.169 23.6945 105.027 24.548 106.085 24.548Z'
            fill='white'
          />
          <path
            d='M57.4947 31.4714C58.5525 31.4714 59.4101 30.6179 59.4101 29.565C59.4101 28.5122 58.5525 27.6587 57.4947 27.6587C56.4369 27.6587 55.5793 28.5122 55.5793 29.565C55.5793 30.6179 56.4369 31.4714 57.4947 31.4714Z'
            fill='white'
          />
          <path
            d='M64.417 31.4714C65.4748 31.4714 66.3323 30.6179 66.3323 29.565C66.3323 28.5122 65.4748 27.6587 64.417 27.6587C63.3591 27.6587 62.5016 28.5122 62.5016 29.565C62.5016 30.6179 63.3591 31.4714 64.417 31.4714Z'
            fill='white'
          />
          <path
            d='M71.3728 31.4714C72.4306 31.4714 73.2881 30.6179 73.2881 29.565C73.2881 28.5122 72.4306 27.6587 71.3728 27.6587C70.3149 27.6587 69.4574 28.5122 69.4574 29.565C69.4574 30.6179 70.3149 31.4714 71.3728 31.4714Z'
            fill='white'
          />
          <path
            d='M78.2949 31.4714C79.3528 31.4714 80.2103 30.6179 80.2103 29.565C80.2103 28.5122 79.3528 27.6587 78.2949 27.6587C77.2371 27.6587 76.3796 28.5122 76.3796 29.565C76.3796 30.6179 77.2371 31.4714 78.2949 31.4714Z'
            fill='white'
          />
          <path
            d='M85.2508 31.4714C86.3086 31.4714 87.1661 30.6179 87.1661 29.565C87.1661 28.5122 86.3086 27.6587 85.2508 27.6587C84.1929 27.6587 83.3354 28.5122 83.3354 29.565C83.3354 30.6179 84.1929 31.4714 85.2508 31.4714Z'
            fill='white'
          />
          <path
            d='M92.2066 31.4714C93.2645 31.4714 94.122 30.6179 94.122 29.565C94.122 28.5122 93.2645 27.6587 92.2066 27.6587C91.1488 27.6587 90.2913 28.5122 90.2913 29.565C90.2913 30.6179 91.1488 31.4714 92.2066 31.4714Z'
            fill='white'
          />
          <path
            d='M99.1288 31.4714C100.187 31.4714 101.044 30.6179 101.044 29.565C101.044 28.5122 100.187 27.6587 99.1288 27.6587C98.071 27.6587 97.2134 28.5122 97.2134 29.565C97.2134 30.6179 98.071 31.4714 99.1288 31.4714Z'
            fill='white'
          />
          <path
            d='M106.085 31.4714C107.142 31.4714 108 30.6179 108 29.565C108 28.5122 107.142 27.6587 106.085 27.6587C105.027 27.6587 104.169 28.5122 104.169 29.565C104.169 30.6179 105.027 31.4714 106.085 31.4714Z'
            fill='white'
          />
          <path
            d='M57.4947 38.3942C58.5525 38.3942 59.4101 37.5407 59.4101 36.4879C59.4101 35.435 58.5525 34.5815 57.4947 34.5815C56.4369 34.5815 55.5793 35.435 55.5793 36.4879C55.5793 37.5407 56.4369 38.3942 57.4947 38.3942Z'
            fill='white'
          />
          <path
            d='M64.417 38.3942C65.4748 38.3942 66.3323 37.5407 66.3323 36.4879C66.3323 35.435 65.4748 34.5815 64.417 34.5815C63.3591 34.5815 62.5016 35.435 62.5016 36.4879C62.5016 37.5407 63.3591 38.3942 64.417 38.3942Z'
            fill='white'
          />
          <path
            d='M71.3728 38.3942C72.4306 38.3942 73.2881 37.5407 73.2881 36.4879C73.2881 35.435 72.4306 34.5815 71.3728 34.5815C70.3149 34.5815 69.4574 35.435 69.4574 36.4879C69.4574 37.5407 70.3149 38.3942 71.3728 38.3942Z'
            fill='white'
          />
          <path
            d='M78.2949 38.3942C79.3528 38.3942 80.2103 37.5407 80.2103 36.4879C80.2103 35.435 79.3528 34.5815 78.2949 34.5815C77.2371 34.5815 76.3796 35.435 76.3796 36.4879C76.3796 37.5407 77.2371 38.3942 78.2949 38.3942Z'
            fill='white'
          />
          <path
            d='M85.2508 38.3942C86.3086 38.3942 87.1661 37.5407 87.1661 36.4879C87.1661 35.435 86.3086 34.5815 85.2508 34.5815C84.1929 34.5815 83.3354 35.435 83.3354 36.4879C83.3354 37.5407 84.1929 38.3942 85.2508 38.3942Z'
            fill='white'
          />
          <path
            d='M92.2066 38.3942C93.2645 38.3942 94.122 37.5407 94.122 36.4879C94.122 35.435 93.2645 34.5815 92.2066 34.5815C91.1488 34.5815 90.2913 35.435 90.2913 36.4879C90.2913 37.5407 91.1488 38.3942 92.2066 38.3942Z'
            fill='white'
          />
          <path
            d='M99.1288 38.3942C100.187 38.3942 101.044 37.5407 101.044 36.4879C101.044 35.435 100.187 34.5815 99.1288 34.5815C98.071 34.5815 97.2134 35.435 97.2134 36.4879C97.2134 37.5407 98.071 38.3942 99.1288 38.3942Z'
            fill='white'
          />
          <path
            d='M106.085 38.3942C107.142 38.3942 108 37.5407 108 36.4879C108 35.435 107.142 34.5815 106.085 34.5815C105.027 34.5815 104.169 35.435 104.169 36.4879C104.169 37.5407 105.027 38.3942 106.085 38.3942Z'
            fill='white'
          />
          <path
            d='M57.4947 45.2839C58.5525 45.2839 59.4101 44.4304 59.4101 43.3775C59.4101 42.3247 58.5525 41.4712 57.4947 41.4712C56.4369 41.4712 55.5793 42.3247 55.5793 43.3775C55.5793 44.4304 56.4369 45.2839 57.4947 45.2839Z'
            fill='white'
          />
          <path
            d='M64.417 45.2839C65.4748 45.2839 66.3323 44.4304 66.3323 43.3775C66.3323 42.3247 65.4748 41.4712 64.417 41.4712C63.3591 41.4712 62.5016 42.3247 62.5016 43.3775C62.5016 44.4304 63.3591 45.2839 64.417 45.2839Z'
            fill='white'
          />
          <path
            d='M71.3728 45.2839C72.4306 45.2839 73.2881 44.4304 73.2881 43.3775C73.2881 42.3247 72.4306 41.4712 71.3728 41.4712C70.3149 41.4712 69.4574 42.3247 69.4574 43.3775C69.4574 44.4304 70.3149 45.2839 71.3728 45.2839Z'
            fill='white'
          />
          <path
            d='M78.2949 45.2839C79.3528 45.2839 80.2103 44.4304 80.2103 43.3775C80.2103 42.3247 79.3528 41.4712 78.2949 41.4712C77.2371 41.4712 76.3796 42.3247 76.3796 43.3775C76.3796 44.4304 77.2371 45.2839 78.2949 45.2839Z'
            fill='white'
          />
          <path
            d='M85.2508 45.2839C86.3086 45.2839 87.1661 44.4304 87.1661 43.3775C87.1661 42.3247 86.3086 41.4712 85.2508 41.4712C84.1929 41.4712 83.3354 42.3247 83.3354 43.3775C83.3354 44.4304 84.1929 45.2839 85.2508 45.2839Z'
            fill='white'
          />
          <path
            d='M92.2066 45.2839C93.2645 45.2839 94.122 44.4304 94.122 43.3775C94.122 42.3247 93.2645 41.4712 92.2066 41.4712C91.1488 41.4712 90.2913 42.3247 90.2913 43.3775C90.2913 44.4304 91.1488 45.2839 92.2066 45.2839Z'
            fill='white'
          />
          <path
            d='M99.1288 45.2839C100.187 45.2839 101.044 44.4304 101.044 43.3775C101.044 42.3247 100.187 41.4712 99.1288 41.4712C98.071 41.4712 97.2134 42.3247 97.2134 43.3775C97.2134 44.4304 98.071 45.2839 99.1288 45.2839Z'
            fill='white'
          />
          <path
            d='M106.085 45.2839C107.142 45.2839 108 44.4304 108 43.3775C108 42.3247 107.142 41.4712 106.085 41.4712C105.027 41.4712 104.169 42.3247 104.169 43.3775C104.169 44.4304 105.027 45.2839 106.085 45.2839Z'
            fill='white'
          />
          <path
            d='M50.5389 52.2067C51.5967 52.2067 52.4543 51.3532 52.4543 50.3004C52.4543 49.2475 51.5967 48.394 50.5389 48.394C49.4811 48.394 48.6235 49.2475 48.6235 50.3004C48.6235 51.3532 49.4811 52.2067 50.5389 52.2067Z'
            fill='white'
          />
          <path
            d='M57.4947 52.2067C58.5525 52.2067 59.4101 51.3532 59.4101 50.3004C59.4101 49.2475 58.5525 48.394 57.4947 48.394C56.4369 48.394 55.5793 49.2475 55.5793 50.3004C55.5793 51.3532 56.4369 52.2067 57.4947 52.2067Z'
            fill='white'
          />
          <path
            d='M64.417 52.2067C65.4748 52.2067 66.3323 51.3532 66.3323 50.3004C66.3323 49.2475 65.4748 48.394 64.417 48.394C63.3591 48.394 62.5016 49.2475 62.5016 50.3004C62.5016 51.3532 63.3591 52.2067 64.417 52.2067Z'
            fill='white'
          />
          <path
            d='M71.3728 52.2067C72.4306 52.2067 73.2881 51.3532 73.2881 50.3004C73.2881 49.2475 72.4306 48.394 71.3728 48.394C70.3149 48.394 69.4574 49.2475 69.4574 50.3004C69.4574 51.3532 70.3149 52.2067 71.3728 52.2067Z'
            fill='white'
          />
          <path
            d='M78.2949 52.2067C79.3528 52.2067 80.2103 51.3532 80.2103 50.3004C80.2103 49.2475 79.3528 48.394 78.2949 48.394C77.2371 48.394 76.3796 49.2475 76.3796 50.3004C76.3796 51.3532 77.2371 52.2067 78.2949 52.2067Z'
            fill='white'
          />
          <path
            d='M85.2508 52.2067C86.3086 52.2067 87.1661 51.3532 87.1661 50.3004C87.1661 49.2475 86.3086 48.394 85.2508 48.394C84.1929 48.394 83.3354 49.2475 83.3354 50.3004C83.3354 51.3532 84.1929 52.2067 85.2508 52.2067Z'
            fill='white'
          />
          <path
            d='M92.2066 52.2067C93.2645 52.2067 94.122 51.3532 94.122 50.3004C94.122 49.2475 93.2645 48.394 92.2066 48.394C91.1488 48.394 90.2913 49.2475 90.2913 50.3004C90.2913 51.3532 91.1488 52.2067 92.2066 52.2067Z'
            fill='white'
          />
          <path
            d='M99.1288 52.2067C100.187 52.2067 101.044 51.3532 101.044 50.3004C101.044 49.2475 100.187 48.394 99.1288 48.394C98.071 48.394 97.2134 49.2475 97.2134 50.3004C97.2134 51.3532 98.071 52.2067 99.1288 52.2067Z'
            fill='white'
          />
          <path
            d='M43.5831 59.0964C44.6409 59.0964 45.4985 58.2429 45.4985 57.19C45.4985 56.1372 44.6409 55.2837 43.5831 55.2837C42.5253 55.2837 41.6677 56.1372 41.6677 57.19C41.6677 58.2429 42.5253 59.0964 43.5831 59.0964Z'
            fill='white'
          />
          <path
            d='M50.5389 59.0964C51.5967 59.0964 52.4543 58.2429 52.4543 57.19C52.4543 56.1372 51.5967 55.2837 50.5389 55.2837C49.4811 55.2837 48.6235 56.1372 48.6235 57.19C48.6235 58.2429 49.4811 59.0964 50.5389 59.0964Z'
            fill='white'
          />
          <path
            d='M57.4947 59.0964C58.5525 59.0964 59.4101 58.2429 59.4101 57.19C59.4101 56.1372 58.5525 55.2837 57.4947 55.2837C56.4369 55.2837 55.5793 56.1372 55.5793 57.19C55.5793 58.2429 56.4369 59.0964 57.4947 59.0964Z'
            fill='white'
          />
          <path
            d='M64.417 59.0964C65.4748 59.0964 66.3323 58.2429 66.3323 57.19C66.3323 56.1372 65.4748 55.2837 64.417 55.2837C63.3591 55.2837 62.5016 56.1372 62.5016 57.19C62.5016 58.2429 63.3591 59.0964 64.417 59.0964Z'
            fill='white'
          />
          <path
            d='M71.3728 59.0964C72.4306 59.0964 73.2881 58.2429 73.2881 57.19C73.2881 56.1372 72.4306 55.2837 71.3728 55.2837C70.3149 55.2837 69.4574 56.1372 69.4574 57.19C69.4574 58.2429 70.3149 59.0964 71.3728 59.0964Z'
            fill='white'
          />
          <path
            d='M78.2949 59.0964C79.3528 59.0964 80.2103 58.2429 80.2103 57.19C80.2103 56.1372 79.3528 55.2837 78.2949 55.2837C77.2371 55.2837 76.3796 56.1372 76.3796 57.19C76.3796 58.2429 77.2371 59.0964 78.2949 59.0964Z'
            fill='white'
          />
          <path
            d='M85.2508 59.0964C86.3086 59.0964 87.1661 58.2429 87.1661 57.19C87.1661 56.1372 86.3086 55.2837 85.2508 55.2837C84.1929 55.2837 83.3354 56.1372 83.3354 57.19C83.3354 58.2429 84.1929 59.0964 85.2508 59.0964Z'
            fill='white'
          />
          <path
            d='M92.2066 59.0964C93.2645 59.0964 94.122 58.2429 94.122 57.19C94.122 56.1372 93.2645 55.2837 92.2066 55.2837C91.1488 55.2837 90.2913 56.1372 90.2913 57.19C90.2913 58.2429 91.1488 59.0964 92.2066 59.0964Z'
            fill='white'
          />
          <path
            d='M43.5831 66.0192C44.6409 66.0192 45.4985 65.1657 45.4985 64.1129C45.4985 63.06 44.6409 62.2065 43.5831 62.2065C42.5253 62.2065 41.6677 63.06 41.6677 64.1129C41.6677 65.1657 42.5253 66.0192 43.5831 66.0192Z'
            fill='white'
          />
          <path
            d='M50.5389 66.0192C51.5967 66.0192 52.4543 65.1657 52.4543 64.1129C52.4543 63.06 51.5967 62.2065 50.5389 62.2065C49.4811 62.2065 48.6235 63.06 48.6235 64.1129C48.6235 65.1657 49.4811 66.0192 50.5389 66.0192Z'
            fill='white'
          />
          <path
            d='M57.4947 66.0192C58.5525 66.0192 59.4101 65.1657 59.4101 64.1129C59.4101 63.06 58.5525 62.2065 57.4947 62.2065C56.4369 62.2065 55.5793 63.06 55.5793 64.1129C55.5793 65.1657 56.4369 66.0192 57.4947 66.0192Z'
            fill='white'
          />
          <path
            d='M64.417 66.0192C65.4748 66.0192 66.3323 65.1657 66.3323 64.1129C66.3323 63.06 65.4748 62.2065 64.417 62.2065C63.3591 62.2065 62.5016 63.06 62.5016 64.1129C62.5016 65.1657 63.3591 66.0192 64.417 66.0192Z'
            fill='white'
          />
          <path
            d='M71.3728 66.0192C72.4306 66.0192 73.2881 65.1657 73.2881 64.1129C73.2881 63.06 72.4306 62.2065 71.3728 62.2065C70.3149 62.2065 69.4574 63.06 69.4574 64.1129C69.4574 65.1657 70.3149 66.0192 71.3728 66.0192Z'
            fill='white'
          />
          <path
            d='M78.2949 66.0192C79.3528 66.0192 80.2103 65.1657 80.2103 64.1129C80.2103 63.06 79.3528 62.2065 78.2949 62.2065C77.2371 62.2065 76.3796 63.06 76.3796 64.1129C76.3796 65.1657 77.2371 66.0192 78.2949 66.0192Z'
            fill='white'
          />
          <path
            d='M85.2508 66.0192C86.3086 66.0192 87.1661 65.1657 87.1661 64.1129C87.1661 63.06 86.3086 62.2065 85.2508 62.2065C84.1929 62.2065 83.3354 63.06 83.3354 64.1129C83.3354 65.1657 84.1929 66.0192 85.2508 66.0192Z'
            fill='white'
          />
          <path
            d='M43.5831 72.9425C44.6409 72.9425 45.4985 72.0891 45.4985 71.0362C45.4985 69.9834 44.6409 69.1299 43.5831 69.1299C42.5253 69.1299 41.6677 69.9834 41.6677 71.0362C41.6677 72.0891 42.5253 72.9425 43.5831 72.9425Z'
            fill='white'
          />
          <path
            d='M50.5389 72.9425C51.5967 72.9425 52.4543 72.0891 52.4543 71.0362C52.4543 69.9834 51.5967 69.1299 50.5389 69.1299C49.4811 69.1299 48.6235 69.9834 48.6235 71.0362C48.6235 72.0891 49.4811 72.9425 50.5389 72.9425Z'
            fill='white'
          />
          <path
            d='M57.4947 72.9425C58.5525 72.9425 59.4101 72.0891 59.4101 71.0362C59.4101 69.9834 58.5525 69.1299 57.4947 69.1299C56.4369 69.1299 55.5793 69.9834 55.5793 71.0362C55.5793 72.0891 56.4369 72.9425 57.4947 72.9425Z'
            fill='white'
          />
          <path
            d='M64.417 72.9425C65.4748 72.9425 66.3323 72.0891 66.3323 71.0362C66.3323 69.9834 65.4748 69.1299 64.417 69.1299C63.3591 69.1299 62.5016 69.9834 62.5016 71.0362C62.5016 72.0891 63.3591 72.9425 64.417 72.9425Z'
            fill='white'
          />
          <path
            d='M71.3728 72.9425C72.4306 72.9425 73.2881 72.0891 73.2881 71.0362C73.2881 69.9834 72.4306 69.1299 71.3728 69.1299C70.3149 69.1299 69.4574 69.9834 69.4574 71.0362C69.4574 72.0891 70.3149 72.9425 71.3728 72.9425Z'
            fill='white'
          />
          <path
            d='M78.2949 72.9425C79.3528 72.9425 80.2103 72.0891 80.2103 71.0362C80.2103 69.9834 79.3528 69.1299 78.2949 69.1299C77.2371 69.1299 76.3796 69.9834 76.3796 71.0362C76.3796 72.0891 77.2371 72.9425 78.2949 72.9425Z'
            fill='white'
          />
          <path
            d='M85.2508 72.9425C86.3086 72.9425 87.1661 72.0891 87.1661 71.0362C87.1661 69.9834 86.3086 69.1299 85.2508 69.1299C84.1929 69.1299 83.3354 69.9834 83.3354 71.0362C83.3354 72.0891 84.1929 72.9425 85.2508 72.9425Z'
            fill='white'
          />
          <path
            d='M43.5831 79.8317C44.6409 79.8317 45.4985 78.9782 45.4985 77.9254C45.4985 76.8725 44.6409 76.019 43.5831 76.019C42.5253 76.019 41.6677 76.8725 41.6677 77.9254C41.6677 78.9782 42.5253 79.8317 43.5831 79.8317Z'
            fill='white'
          />
          <path
            d='M50.5389 79.8317C51.5967 79.8317 52.4543 78.9782 52.4543 77.9254C52.4543 76.8725 51.5967 76.019 50.5389 76.019C49.4811 76.019 48.6235 76.8725 48.6235 77.9254C48.6235 78.9782 49.4811 79.8317 50.5389 79.8317Z'
            fill='white'
          />
          <path
            d='M57.4947 79.8317C58.5525 79.8317 59.4101 78.9782 59.4101 77.9254C59.4101 76.8725 58.5525 76.019 57.4947 76.019C56.4369 76.019 55.5793 76.8725 55.5793 77.9254C55.5793 78.9782 56.4369 79.8317 57.4947 79.8317Z'
            fill='white'
          />
          <path
            d='M64.417 79.8317C65.4748 79.8317 66.3323 78.9782 66.3323 77.9254C66.3323 76.8725 65.4748 76.019 64.417 76.019C63.3591 76.019 62.5016 76.8725 62.5016 77.9254C62.5016 78.9782 63.3591 79.8317 64.417 79.8317Z'
            fill='white'
          />
          <path
            d='M71.3728 79.8317C72.4306 79.8317 73.2881 78.9782 73.2881 77.9254C73.2881 76.8725 72.4306 76.019 71.3728 76.019C70.3149 76.019 69.4574 76.8725 69.4574 77.9254C69.4574 78.9782 70.3149 79.8317 71.3728 79.8317Z'
            fill='white'
          />
          <path
            d='M78.2949 79.8317C79.3528 79.8317 80.2103 78.9782 80.2103 77.9254C80.2103 76.8725 79.3528 76.019 78.2949 76.019C77.2371 76.019 76.3796 76.8725 76.3796 77.9254C76.3796 78.9782 77.2371 79.8317 78.2949 79.8317Z'
            fill='white'
          />
          <path
            d='M85.2508 79.8317C86.3086 79.8317 87.1661 78.9782 87.1661 77.9254C87.1661 76.8725 86.3086 76.019 85.2508 76.019C84.1929 76.019 83.3354 76.8725 83.3354 77.9254C83.3354 78.9782 84.1929 79.8317 85.2508 79.8317Z'
            fill='white'
          />
          <path
            d='M43.5831 86.7551C44.6409 86.7551 45.4985 85.9016 45.4985 84.8487C45.4985 83.7959 44.6409 82.9424 43.5831 82.9424C42.5253 82.9424 41.6677 83.7959 41.6677 84.8487C41.6677 85.9016 42.5253 86.7551 43.5831 86.7551Z'
            fill='white'
          />
          <path
            d='M50.5389 86.7551C51.5967 86.7551 52.4543 85.9016 52.4543 84.8487C52.4543 83.7959 51.5967 82.9424 50.5389 82.9424C49.4811 82.9424 48.6235 83.7959 48.6235 84.8487C48.6235 85.9016 49.4811 86.7551 50.5389 86.7551Z'
            fill='white'
          />
          <path
            d='M57.4947 86.7551C58.5525 86.7551 59.4101 85.9016 59.4101 84.8487C59.4101 83.7959 58.5525 82.9424 57.4947 82.9424C56.4369 82.9424 55.5793 83.7959 55.5793 84.8487C55.5793 85.9016 56.4369 86.7551 57.4947 86.7551Z'
            fill='white'
          />
          <path
            d='M64.417 86.7551C65.4748 86.7551 66.3323 85.9016 66.3323 84.8487C66.3323 83.7959 65.4748 82.9424 64.417 82.9424C63.3591 82.9424 62.5016 83.7959 62.5016 84.8487C62.5016 85.9016 63.3591 86.7551 64.417 86.7551Z'
            fill='white'
          />
          <path
            d='M71.3728 86.7551C72.4306 86.7551 73.2881 85.9016 73.2881 84.8487C73.2881 83.7959 72.4306 82.9424 71.3728 82.9424C70.3149 82.9424 69.4574 83.7959 69.4574 84.8487C69.4574 85.9016 70.3149 86.7551 71.3728 86.7551Z'
            fill='white'
          />
          <path
            d='M78.2949 86.7551C79.3528 86.7551 80.2103 85.9016 80.2103 84.8487C80.2103 83.7959 79.3528 82.9424 78.2949 82.9424C77.2371 82.9424 76.3796 83.7959 76.3796 84.8487C76.3796 85.9016 77.2371 86.7551 78.2949 86.7551Z'
            fill='white'
          />
          <path
            d='M85.2508 86.7551C86.3086 86.7551 87.1661 85.9016 87.1661 84.8487C87.1661 83.7959 86.3086 82.9424 85.2508 82.9424C84.1929 82.9424 83.3354 83.7959 83.3354 84.8487C83.3354 85.9016 84.1929 86.7551 85.2508 86.7551Z'
            fill='white'
          />
          <path
            d='M36.6609 93.6779C37.7187 93.6779 38.5762 92.8244 38.5762 91.7716C38.5762 90.7187 37.7187 89.8652 36.6609 89.8652C35.603 89.8652 34.7455 90.7187 34.7455 91.7716C34.7455 92.8244 35.603 93.6779 36.6609 93.6779Z'
            fill='white'
          />
          <path
            d='M43.5831 93.6779C44.6409 93.6779 45.4985 92.8244 45.4985 91.7716C45.4985 90.7187 44.6409 89.8652 43.5831 89.8652C42.5253 89.8652 41.6677 90.7187 41.6677 91.7716C41.6677 92.8244 42.5253 93.6779 43.5831 93.6779Z'
            fill='white'
          />
          <path
            d='M50.5389 93.6779C51.5967 93.6779 52.4543 92.8244 52.4543 91.7716C52.4543 90.7187 51.5967 89.8652 50.5389 89.8652C49.4811 89.8652 48.6235 90.7187 48.6235 91.7716C48.6235 92.8244 49.4811 93.6779 50.5389 93.6779Z'
            fill='white'
          />
          <path
            d='M57.4947 93.6779C58.5525 93.6779 59.4101 92.8244 59.4101 91.7716C59.4101 90.7187 58.5525 89.8652 57.4947 89.8652C56.4369 89.8652 55.5793 90.7187 55.5793 91.7716C55.5793 92.8244 56.4369 93.6779 57.4947 93.6779Z'
            fill='white'
          />
          <path
            d='M64.417 93.6779C65.4748 93.6779 66.3323 92.8244 66.3323 91.7716C66.3323 90.7187 65.4748 89.8652 64.417 89.8652C63.3591 89.8652 62.5016 90.7187 62.5016 91.7716C62.5016 92.8244 63.3591 93.6779 64.417 93.6779Z'
            fill='white'
          />
          <path
            d='M71.3728 93.6779C72.4306 93.6779 73.2881 92.8244 73.2881 91.7716C73.2881 90.7187 72.4306 89.8652 71.3728 89.8652C70.3149 89.8652 69.4574 90.7187 69.4574 91.7716C69.4574 92.8244 70.3149 93.6779 71.3728 93.6779Z'
            fill='white'
          />
          <path
            d='M78.2949 93.6779C79.3528 93.6779 80.2103 92.8244 80.2103 91.7716C80.2103 90.7187 79.3528 89.8652 78.2949 89.8652C77.2371 89.8652 76.3796 90.7187 76.3796 91.7716C76.3796 92.8244 77.2371 93.6779 78.2949 93.6779Z'
            fill='white'
          />
          <path
            d='M85.2508 93.6779C86.3086 93.6779 87.1661 92.8244 87.1661 91.7716C87.1661 90.7187 86.3086 89.8652 85.2508 89.8652C84.1929 89.8652 83.3354 90.7187 83.3354 91.7716C83.3354 92.8244 84.1929 93.6779 85.2508 93.6779Z'
            fill='white'
          />
          <path
            d='M36.6609 100.568C37.7187 100.568 38.5762 99.7141 38.5762 98.6612C38.5762 97.6084 37.7187 96.7549 36.6609 96.7549C35.603 96.7549 34.7455 97.6084 34.7455 98.6612C34.7455 99.7141 35.603 100.568 36.6609 100.568Z'
            fill='white'
          />
          <path
            d='M43.5831 100.568C44.6409 100.568 45.4985 99.7141 45.4985 98.6612C45.4985 97.6084 44.6409 96.7549 43.5831 96.7549C42.5253 96.7549 41.6677 97.6084 41.6677 98.6612C41.6677 99.7141 42.5253 100.568 43.5831 100.568Z'
            fill='white'
          />
          <path
            d='M50.5389 100.568C51.5967 100.568 52.4543 99.7141 52.4543 98.6612C52.4543 97.6084 51.5967 96.7549 50.5389 96.7549C49.4811 96.7549 48.6235 97.6084 48.6235 98.6612C48.6235 99.7141 49.4811 100.568 50.5389 100.568Z'
            fill='white'
          />
          <path
            d='M57.4947 100.568C58.5525 100.568 59.4101 99.7141 59.4101 98.6612C59.4101 97.6084 58.5525 96.7549 57.4947 96.7549C56.4369 96.7549 55.5793 97.6084 55.5793 98.6612C55.5793 99.7141 56.4369 100.568 57.4947 100.568Z'
            fill='white'
          />
          <path
            d='M64.417 100.568C65.4748 100.568 66.3323 99.7141 66.3323 98.6612C66.3323 97.6084 65.4748 96.7549 64.417 96.7549C63.3591 96.7549 62.5016 97.6084 62.5016 98.6612C62.5016 99.7141 63.3591 100.568 64.417 100.568Z'
            fill='white'
          />
          <path
            d='M71.3728 100.568C72.4306 100.568 73.2881 99.7141 73.2881 98.6612C73.2881 97.6084 72.4306 96.7549 71.3728 96.7549C70.3149 96.7549 69.4574 97.6084 69.4574 98.6612C69.4574 99.7141 70.3149 100.568 71.3728 100.568Z'
            fill='white'
          />
          <path
            d='M78.2949 100.568C79.3528 100.568 80.2103 99.7141 80.2103 98.6612C80.2103 97.6084 79.3528 96.7549 78.2949 96.7549C77.2371 96.7549 76.3796 97.6084 76.3796 98.6612C76.3796 99.7141 77.2371 100.568 78.2949 100.568Z'
            fill='white'
          />
          <path
            d='M85.2508 100.568C86.3086 100.568 87.1661 99.7141 87.1661 98.6612C87.1661 97.6084 86.3086 96.7549 85.2508 96.7549C84.1929 96.7549 83.3354 97.6084 83.3354 98.6612C83.3354 99.7141 84.1929 100.568 85.2508 100.568Z'
            fill='white'
          />
          <path
            d='M36.6609 107.49C37.7187 107.49 38.5762 106.637 38.5762 105.584C38.5762 104.531 37.7187 103.678 36.6609 103.678C35.603 103.678 34.7455 104.531 34.7455 105.584C34.7455 106.637 35.603 107.49 36.6609 107.49Z'
            fill='white'
          />
          <path
            d='M43.5831 107.49C44.6409 107.49 45.4985 106.637 45.4985 105.584C45.4985 104.531 44.6409 103.678 43.5831 103.678C42.5253 103.678 41.6677 104.531 41.6677 105.584C41.6677 106.637 42.5253 107.49 43.5831 107.49Z'
            fill='white'
          />
          <path
            d='M50.5389 107.49C51.5967 107.49 52.4543 106.637 52.4543 105.584C52.4543 104.531 51.5967 103.678 50.5389 103.678C49.4811 103.678 48.6235 104.531 48.6235 105.584C48.6235 106.637 49.4811 107.49 50.5389 107.49Z'
            fill='white'
          />
          <path
            d='M57.4947 107.49C58.5525 107.49 59.4101 106.637 59.4101 105.584C59.4101 104.531 58.5525 103.678 57.4947 103.678C56.4369 103.678 55.5793 104.531 55.5793 105.584C55.5793 106.637 56.4369 107.49 57.4947 107.49Z'
            fill='white'
          />
          <path
            d='M64.417 107.49C65.4748 107.49 66.3323 106.637 66.3323 105.584C66.3323 104.531 65.4748 103.678 64.417 103.678C63.3591 103.678 62.5016 104.531 62.5016 105.584C62.5016 106.637 63.3591 107.49 64.417 107.49Z'
            fill='white'
          />
          <path
            d='M71.3728 107.49C72.4306 107.49 73.2881 106.637 73.2881 105.584C73.2881 104.531 72.4306 103.678 71.3728 103.678C70.3149 103.678 69.4574 104.531 69.4574 105.584C69.4574 106.637 70.3149 107.49 71.3728 107.49Z'
            fill='white'
          />
          <path
            d='M78.2949 107.49C79.3528 107.49 80.2103 106.637 80.2103 105.584C80.2103 104.531 79.3528 103.678 78.2949 103.678C77.2371 103.678 76.3796 104.531 76.3796 105.584C76.3796 106.637 77.2371 107.49 78.2949 107.49Z'
            fill='white'
          />
          <path
            d='M29.705 114.413C30.7629 114.413 31.6204 113.56 31.6204 112.507C31.6204 111.454 30.7629 110.601 29.705 110.601C28.6472 110.601 27.7897 111.454 27.7897 112.507C27.7897 113.56 28.6472 114.413 29.705 114.413Z'
            fill='white'
          />
          <path
            d='M36.6609 114.413C37.7187 114.413 38.5762 113.56 38.5762 112.507C38.5762 111.454 37.7187 110.601 36.6609 110.601C35.603 110.601 34.7455 111.454 34.7455 112.507C34.7455 113.56 35.603 114.413 36.6609 114.413Z'
            fill='white'
          />
          <path
            d='M43.5831 114.413C44.6409 114.413 45.4985 113.56 45.4985 112.507C45.4985 111.454 44.6409 110.601 43.5831 110.601C42.5253 110.601 41.6677 111.454 41.6677 112.507C41.6677 113.56 42.5253 114.413 43.5831 114.413Z'
            fill='white'
          />
          <path
            d='M50.5389 114.413C51.5967 114.413 52.4543 113.56 52.4543 112.507C52.4543 111.454 51.5967 110.601 50.5389 110.601C49.4811 110.601 48.6235 111.454 48.6235 112.507C48.6235 113.56 49.4811 114.413 50.5389 114.413Z'
            fill='white'
          />
          <path
            d='M57.4947 114.413C58.5525 114.413 59.4101 113.56 59.4101 112.507C59.4101 111.454 58.5525 110.601 57.4947 110.601C56.4369 110.601 55.5793 111.454 55.5793 112.507C55.5793 113.56 56.4369 114.413 57.4947 114.413Z'
            fill='white'
          />
          <path
            d='M64.417 114.413C65.4748 114.413 66.3323 113.56 66.3323 112.507C66.3323 111.454 65.4748 110.601 64.417 110.601C63.3591 110.601 62.5016 111.454 62.5016 112.507C62.5016 113.56 63.3591 114.413 64.417 114.413Z'
            fill='white'
          />
          <path
            d='M71.3728 114.413C72.4306 114.413 73.2881 113.56 73.2881 112.507C73.2881 111.454 72.4306 110.601 71.3728 110.601C70.3149 110.601 69.4574 111.454 69.4574 112.507C69.4574 113.56 70.3149 114.413 71.3728 114.413Z'
            fill='white'
          />
          <path
            d='M78.2949 114.413C79.3528 114.413 80.2103 113.56 80.2103 112.507C80.2103 111.454 79.3528 110.601 78.2949 110.601C77.2371 110.601 76.3796 111.454 76.3796 112.507C76.3796 113.56 77.2371 114.413 78.2949 114.413Z'
            fill='white'
          />
          <path
            d='M29.705 121.303C30.7629 121.303 31.6204 120.449 31.6204 119.397C31.6204 118.344 30.7629 117.49 29.705 117.49C28.6472 117.49 27.7897 118.344 27.7897 119.397C27.7897 120.449 28.6472 121.303 29.705 121.303Z'
            fill='white'
          />
          <path
            d='M36.6609 121.303C37.7187 121.303 38.5762 120.449 38.5762 119.397C38.5762 118.344 37.7187 117.49 36.6609 117.49C35.603 117.49 34.7455 118.344 34.7455 119.397C34.7455 120.449 35.603 121.303 36.6609 121.303Z'
            fill='white'
          />
          <path
            d='M43.5831 121.303C44.6409 121.303 45.4985 120.449 45.4985 119.397C45.4985 118.344 44.6409 117.49 43.5831 117.49C42.5253 117.49 41.6677 118.344 41.6677 119.397C41.6677 120.449 42.5253 121.303 43.5831 121.303Z'
            fill='white'
          />
          <path
            d='M50.5389 121.303C51.5967 121.303 52.4543 120.449 52.4543 119.397C52.4543 118.344 51.5967 117.49 50.5389 117.49C49.4811 117.49 48.6235 118.344 48.6235 119.397C48.6235 120.449 49.4811 121.303 50.5389 121.303Z'
            fill='white'
          />
          {/* <path
            className='al-aqsa'
            d='M57.4947 121.303C58.5525 121.303 59.4101 120.449 59.4101 119.397C59.4101 118.344 58.5525 117.49 57.4947 117.49C56.4369 117.49 55.5793 118.344 55.5793 119.397C55.5793 120.449 56.4369 121.303 57.4947 121.303Z'
            fill='#FFD700'
          /> */}
          <g
            className='al-aqsa'
            transform='rotate(45, 100, 100)'
            style={{ transform: "translate(55px, 118px) scale(0.024)" }}>
            <path
              d='M 0 100
       A 100 100 0 0 1 200 100
       L 0 100
       Z'
              fill='#FFD700'
            />
          </g>
          <path
            d='M64.417 121.303C65.4748 121.303 66.3323 120.449 66.3323 119.397C66.3323 118.344 65.4748 117.49 64.417 117.49C63.3591 117.49 62.5016 118.344 62.5016 119.397C62.5016 120.449 63.3591 121.303 64.417 121.303Z'
            fill='white'
          />
          <path
            d='M71.3728 121.303C72.4306 121.303 73.2881 120.449 73.2881 119.397C73.2881 118.344 72.4306 117.49 71.3728 117.49C70.3149 117.49 69.4574 118.344 69.4574 119.397C69.4574 120.449 70.3149 121.303 71.3728 121.303Z'
            fill='white'
          />
          <path
            d='M78.2949 121.303C79.3528 121.303 80.2103 120.449 80.2103 119.397C80.2103 118.344 79.3528 117.49 78.2949 117.49C77.2371 117.49 76.3796 118.344 76.3796 119.397C76.3796 120.449 77.2371 121.303 78.2949 121.303Z'
            fill='white'
          />
          <path
            d='M29.705 128.226C30.7629 128.226 31.6204 127.372 31.6204 126.319C31.6204 125.267 30.7629 124.413 29.705 124.413C28.6472 124.413 27.7897 125.267 27.7897 126.319C27.7897 127.372 28.6472 128.226 29.705 128.226Z'
            fill='white'
          />
          <path
            d='M36.6609 128.226C37.7187 128.226 38.5762 127.372 38.5762 126.319C38.5762 125.267 37.7187 124.413 36.6609 124.413C35.603 124.413 34.7455 125.267 34.7455 126.319C34.7455 127.372 35.603 128.226 36.6609 128.226Z'
            fill='white'
          />
          <path
            d='M43.5831 128.226C44.6409 128.226 45.4985 127.372 45.4985 126.319C45.4985 125.267 44.6409 124.413 43.5831 124.413C42.5253 124.413 41.6677 125.267 41.6677 126.319C41.6677 127.372 42.5253 128.226 43.5831 128.226Z'
            fill='white'
          />
          <path
            className='al-aqsa'
            d='M50.5389 128.226C51.5967 128.226 52.4543 127.372 52.4543 126.319C52.4543 125.267 51.5967 124.413 50.5389 124.413C49.4811 124.413 48.6235 125.267 48.6235 126.319C48.6235 127.372 49.4811 128.226 50.5389 128.226Z'
            fill='#FFD700'
          />
          <path
            className='al-aqsa'
            d='M57.4947 128.226C58.5525 128.226 59.4101 127.372 59.4101 126.319C59.4101 125.267 58.5525 124.413 57.4947 124.413C56.4369 124.413 55.5793 125.267 55.5793 126.319C55.5793 127.372 56.4369 128.226 57.4947 128.226Z'
            fill='#FFD700'
          />
          <path
            className='al-aqsa'
            d='M64.417 128.226C65.4748 128.226 66.3323 127.372 66.3323 126.319C66.3323 125.267 65.4748 124.413 64.417 124.413C63.3591 124.413 62.5016 125.267 62.5016 126.319C62.5016 127.372 63.3591 128.226 64.417 128.226Z'
            fill='#FFD700'
          />
          <path
            d='M71.3728 128.226C72.4306 128.226 73.2881 127.372 73.2881 126.319C73.2881 125.267 72.4306 124.413 71.3728 124.413C70.3149 124.413 69.4574 125.267 69.4574 126.319C69.4574 127.372 70.3149 128.226 71.3728 128.226Z'
            fill='white'
          />
          <path
            d='M78.2949 128.226C79.3528 128.226 80.2103 127.372 80.2103 126.319C80.2103 125.267 79.3528 124.413 78.2949 124.413C77.2371 124.413 76.3796 125.267 76.3796 126.319C76.3796 127.372 77.2371 128.226 78.2949 128.226Z'
            fill='white'
          />
          <path
            d='M22.7492 135.115C23.8071 135.115 24.6646 134.262 24.6646 133.209C24.6646 132.156 23.8071 131.303 22.7492 131.303C21.6914 131.303 20.8339 132.156 20.8339 133.209C20.8339 134.262 21.6914 135.115 22.7492 135.115Z'
            fill='white'
          />
          <path
            d='M29.705 135.115C30.7629 135.115 31.6204 134.262 31.6204 133.209C31.6204 132.156 30.7629 131.303 29.705 131.303C28.6472 131.303 27.7897 132.156 27.7897 133.209C27.7897 134.262 28.6472 135.115 29.705 135.115Z'
            fill='white'
          />
          <path
            d='M36.6609 135.115C37.7187 135.115 38.5762 134.262 38.5762 133.209C38.5762 132.156 37.7187 131.303 36.6609 131.303C35.603 131.303 34.7455 132.156 34.7455 133.209C34.7455 134.262 35.603 135.115 36.6609 135.115Z'
            fill='white'
          />
          <path
            d='M43.5831 135.115C44.6409 135.115 45.4985 134.262 45.4985 133.209C45.4985 132.156 44.6409 131.303 43.5831 131.303C42.5253 131.303 41.6677 132.156 41.6677 133.209C41.6677 134.262 42.5253 135.115 43.5831 135.115Z'
            fill='white'
          />
          <path
            className='al-aqsa'
            d='M50.5389 135.115C51.5967 135.115 52.4543 134.262 52.4543 133.209C52.4543 132.156 51.5967 131.303 50.5389 131.303C49.4811 131.303 48.6235 132.156 48.6235 133.209C48.6235 134.262 49.4811 135.115 50.5389 135.115Z'
            fill='#FFD700'
          />
          <path
            className='al-aqsa'
            d='M57.4947 135.115C58.5525 135.115 59.4101 134.262 59.4101 133.209C59.4101 132.156 58.5525 131.303 57.4947 131.303C56.4369 131.303 55.5793 132.156 55.5793 133.209C55.5793 134.262 56.4369 135.115 57.4947 135.115Z'
            fill='#FFD700'
          />
          <path
            className='al-aqsa'
            d='M64.417 135.115C65.4748 135.115 66.3323 134.262 66.3323 133.209C66.3323 132.156 65.4748 131.303 64.417 131.303C63.3591 131.303 62.5016 132.156 62.5016 133.209C62.5016 134.262 63.3591 135.115 64.417 135.115Z'
            fill='#FFD700'
          />
          <path
            d='M71.3728 135.115C72.4306 135.115 73.2881 134.262 73.2881 133.209C73.2881 132.156 72.4306 131.303 71.3728 131.303C70.3149 131.303 69.4574 132.156 69.4574 133.209C69.4574 134.262 70.3149 135.115 71.3728 135.115Z'
            fill='white'
          />
          <path
            d='M78.2949 135.115C79.3528 135.115 80.2103 134.262 80.2103 133.209C80.2103 132.156 79.3528 131.303 78.2949 131.303C77.2371 131.303 76.3796 132.156 76.3796 133.209C76.3796 134.262 77.2371 135.115 78.2949 135.115Z'
            fill='white'
          />
          <path
            d='M22.7492 142.039C23.8071 142.039 24.6646 141.185 24.6646 140.132C24.6646 139.08 23.8071 138.226 22.7492 138.226C21.6914 138.226 20.8339 139.08 20.8339 140.132C20.8339 141.185 21.6914 142.039 22.7492 142.039Z'
            fill='white'
          />
          <path
            d='M29.705 142.039C30.7629 142.039 31.6204 141.185 31.6204 140.132C31.6204 139.08 30.7629 138.226 29.705 138.226C28.6472 138.226 27.7897 139.08 27.7897 140.132C27.7897 141.185 28.6472 142.039 29.705 142.039Z'
            fill='white'
          />
          <path
            d='M36.6609 142.039C37.7187 142.039 38.5762 141.185 38.5762 140.132C38.5762 139.08 37.7187 138.226 36.6609 138.226C35.603 138.226 34.7455 139.08 34.7455 140.132C34.7455 141.185 35.603 142.039 36.6609 142.039Z'
            fill='white'
          />
          <path
            d='M43.5831 142.039C44.6409 142.039 45.4985 141.185 45.4985 140.132C45.4985 139.08 44.6409 138.226 43.5831 138.226C42.5253 138.226 41.6677 139.08 41.6677 140.132C41.6677 141.185 42.5253 142.039 43.5831 142.039Z'
            fill='white'
          />
          <path
            d='M50.5389 142.039C51.5967 142.039 52.4543 141.185 52.4543 140.132C52.4543 139.08 51.5967 138.226 50.5389 138.226C49.4811 138.226 48.6235 139.08 48.6235 140.132C48.6235 141.185 49.4811 142.039 50.5389 142.039Z'
            fill='white'
          />
          <path
            d='M57.4947 142.039C58.5525 142.039 59.4101 141.185 59.4101 140.132C59.4101 139.08 58.5525 138.226 57.4947 138.226C56.4369 138.226 55.5793 139.08 55.5793 140.132C55.5793 141.185 56.4369 142.039 57.4947 142.039Z'
            fill='white'
          />
          <path
            d='M64.417 142.039C65.4748 142.039 66.3323 141.185 66.3323 140.132C66.3323 139.08 65.4748 138.226 64.417 138.226C63.3591 138.226 62.5016 139.08 62.5016 140.132C62.5016 141.185 63.3591 142.039 64.417 142.039Z'
            fill='white'
          />
          <path
            d='M71.3728 142.039C72.4306 142.039 73.2881 141.185 73.2881 140.132C73.2881 139.08 72.4306 138.226 71.3728 138.226C70.3149 138.226 69.4574 139.08 69.4574 140.132C69.4574 141.185 70.3149 142.039 71.3728 142.039Z'
            fill='white'
          />
          <path
            d='M78.2949 142.039C79.3528 142.039 80.2103 141.185 80.2103 140.132C80.2103 139.08 79.3528 138.226 78.2949 138.226C77.2371 138.226 76.3796 139.08 76.3796 140.132C76.3796 141.185 77.2371 142.039 78.2949 142.039Z'
            fill='white'
          />
          <path
            d='M15.827 148.962C16.8848 148.962 17.7424 148.108 17.7424 147.055C17.7424 146.002 16.8848 145.149 15.827 145.149C14.7692 145.149 13.9116 146.002 13.9116 147.055C13.9116 148.108 14.7692 148.962 15.827 148.962Z'
            fill='white'
          />
          <path
            d='M22.7492 148.962C23.8071 148.962 24.6646 148.108 24.6646 147.055C24.6646 146.002 23.8071 145.149 22.7492 145.149C21.6914 145.149 20.8339 146.002 20.8339 147.055C20.8339 148.108 21.6914 148.962 22.7492 148.962Z'
            fill='white'
          />
          <path
            d='M29.705 148.962C30.7629 148.962 31.6204 148.108 31.6204 147.055C31.6204 146.002 30.7629 145.149 29.705 145.149C28.6472 145.149 27.7897 146.002 27.7897 147.055C27.7897 148.108 28.6472 148.962 29.705 148.962Z'
            fill='white'
          />
          <path
            d='M36.6609 148.962C37.7187 148.962 38.5762 148.108 38.5762 147.055C38.5762 146.002 37.7187 145.149 36.6609 145.149C35.603 145.149 34.7455 146.002 34.7455 147.055C34.7455 148.108 35.603 148.962 36.6609 148.962Z'
            fill='white'
          />
          <path
            d='M43.5831 148.962C44.6409 148.962 45.4985 148.108 45.4985 147.055C45.4985 146.002 44.6409 145.149 43.5831 145.149C42.5253 145.149 41.6677 146.002 41.6677 147.055C41.6677 148.108 42.5253 148.962 43.5831 148.962Z'
            fill='white'
          />
          <path
            d='M50.5389 148.962C51.5967 148.962 52.4543 148.108 52.4543 147.055C52.4543 146.002 51.5967 145.149 50.5389 145.149C49.4811 145.149 48.6235 146.002 48.6235 147.055C48.6235 148.108 49.4811 148.962 50.5389 148.962Z'
            fill='white'
          />
          <path
            d='M57.4947 148.962C58.5525 148.962 59.4101 148.108 59.4101 147.055C59.4101 146.002 58.5525 145.149 57.4947 145.149C56.4369 145.149 55.5793 146.002 55.5793 147.055C55.5793 148.108 56.4369 148.962 57.4947 148.962Z'
            fill='white'
          />
          <path
            d='M64.417 148.962C65.4748 148.962 66.3323 148.108 66.3323 147.055C66.3323 146.002 65.4748 145.149 64.417 145.149C63.3591 145.149 62.5016 146.002 62.5016 147.055C62.5016 148.108 63.3591 148.962 64.417 148.962Z'
            fill='white'
          />
          <path
            d='M71.3728 148.962C72.4306 148.962 73.2881 148.108 73.2881 147.055C73.2881 146.002 72.4306 145.149 71.3728 145.149C70.3149 145.149 69.4574 146.002 69.4574 147.055C69.4574 148.108 70.3149 148.962 71.3728 148.962Z'
            fill='white'
          />
          <path
            d='M78.2949 148.962C79.3528 148.962 80.2103 148.108 80.2103 147.055C80.2103 146.002 79.3528 145.149 78.2949 145.149C77.2371 145.149 76.3796 146.002 76.3796 147.055C76.3796 148.108 77.2371 148.962 78.2949 148.962Z'
            fill='white'
          />
          <path
            d='M8.87118 155.851C9.92901 155.851 10.7866 154.998 10.7866 153.945C10.7866 152.892 9.92901 152.039 8.87118 152.039C7.81335 152.039 6.95581 152.892 6.95581 153.945C6.95581 154.998 7.81335 155.851 8.87118 155.851Z'
            fill='white'
          />
          <path
            d='M15.827 155.851C16.8848 155.851 17.7424 154.998 17.7424 153.945C17.7424 152.892 16.8848 152.039 15.827 152.039C14.7692 152.039 13.9116 152.892 13.9116 153.945C13.9116 154.998 14.7692 155.851 15.827 155.851Z'
            fill='white'
          />
          <path
            d='M22.7492 155.851C23.8071 155.851 24.6646 154.998 24.6646 153.945C24.6646 152.892 23.8071 152.039 22.7492 152.039C21.6914 152.039 20.8339 152.892 20.8339 153.945C20.8339 154.998 21.6914 155.851 22.7492 155.851Z'
            fill='white'
          />
          <path
            d='M29.705 155.851C30.7629 155.851 31.6204 154.998 31.6204 153.945C31.6204 152.892 30.7629 152.039 29.705 152.039C28.6472 152.039 27.7897 152.892 27.7897 153.945C27.7897 154.998 28.6472 155.851 29.705 155.851Z'
            fill='white'
          />
          <path
            d='M36.6609 155.851C37.7187 155.851 38.5762 154.998 38.5762 153.945C38.5762 152.892 37.7187 152.039 36.6609 152.039C35.603 152.039 34.7455 152.892 34.7455 153.945C34.7455 154.998 35.603 155.851 36.6609 155.851Z'
            fill='white'
          />
          <path
            d='M43.5831 155.851C44.6409 155.851 45.4985 154.998 45.4985 153.945C45.4985 152.892 44.6409 152.039 43.5831 152.039C42.5253 152.039 41.6677 152.892 41.6677 153.945C41.6677 154.998 42.5253 155.851 43.5831 155.851Z'
            fill='white'
          />
          <path
            d='M50.5389 155.851C51.5967 155.851 52.4543 154.998 52.4543 153.945C52.4543 152.892 51.5967 152.039 50.5389 152.039C49.4811 152.039 48.6235 152.892 48.6235 153.945C48.6235 154.998 49.4811 155.851 50.5389 155.851Z'
            fill='white'
          />
          <path
            d='M57.4947 155.851C58.5525 155.851 59.4101 154.998 59.4101 153.945C59.4101 152.892 58.5525 152.039 57.4947 152.039C56.4369 152.039 55.5793 152.892 55.5793 153.945C55.5793 154.998 56.4369 155.851 57.4947 155.851Z'
            fill='white'
          />
          <path
            d='M64.417 155.851C65.4748 155.851 66.3323 154.998 66.3323 153.945C66.3323 152.892 65.4748 152.039 64.417 152.039C63.3591 152.039 62.5016 152.892 62.5016 153.945C62.5016 154.998 63.3591 155.851 64.417 155.851Z'
            fill='white'
          />
          <path
            d='M71.3728 155.851C72.4306 155.851 73.2881 154.998 73.2881 153.945C73.2881 152.892 72.4306 152.039 71.3728 152.039C70.3149 152.039 69.4574 152.892 69.4574 153.945C69.4574 154.998 70.3149 155.851 71.3728 155.851Z'
            fill='white'
          />
          <path
            d='M78.2949 155.851C79.3528 155.851 80.2103 154.998 80.2103 153.945C80.2103 152.892 79.3528 152.039 78.2949 152.039C77.2371 152.039 76.3796 152.892 76.3796 153.945C76.3796 154.998 77.2371 155.851 78.2949 155.851Z'
            fill='white'
          />
          <path
            d='M1.91537 162.774C2.9732 162.774 3.83074 161.921 3.83074 160.868C3.83074 159.815 2.9732 158.961 1.91537 158.961C0.85754 158.961 0 159.815 0 160.868C0 161.921 0.85754 162.774 1.91537 162.774Z'
            fill='white'
          />
          <path
            d='M8.87118 162.774C9.92901 162.774 10.7866 161.921 10.7866 160.868C10.7866 159.815 9.92901 158.961 8.87118 158.961C7.81335 158.961 6.95581 159.815 6.95581 160.868C6.95581 161.921 7.81335 162.774 8.87118 162.774Z'
            fill='white'
          />
          <path
            d='M15.827 162.774C16.8848 162.774 17.7424 161.921 17.7424 160.868C17.7424 159.815 16.8848 158.961 15.827 158.961C14.7692 158.961 13.9116 159.815 13.9116 160.868C13.9116 161.921 14.7692 162.774 15.827 162.774Z'
            fill='white'
          />
          <path
            d='M22.7492 162.774C23.8071 162.774 24.6646 161.921 24.6646 160.868C24.6646 159.815 23.8071 158.961 22.7492 158.961C21.6914 158.961 20.8339 159.815 20.8339 160.868C20.8339 161.921 21.6914 162.774 22.7492 162.774Z'
            fill='white'
          />
          <path
            d='M29.705 162.774C30.7629 162.774 31.6204 161.921 31.6204 160.868C31.6204 159.815 30.7629 158.961 29.705 158.961C28.6472 158.961 27.7897 159.815 27.7897 160.868C27.7897 161.921 28.6472 162.774 29.705 162.774Z'
            fill='white'
          />
          <path
            d='M36.6609 162.774C37.7187 162.774 38.5762 161.921 38.5762 160.868C38.5762 159.815 37.7187 158.961 36.6609 158.961C35.603 158.961 34.7455 159.815 34.7455 160.868C34.7455 161.921 35.603 162.774 36.6609 162.774Z'
            fill='white'
          />
          <path
            d='M43.5831 162.774C44.6409 162.774 45.4985 161.921 45.4985 160.868C45.4985 159.815 44.6409 158.961 43.5831 158.961C42.5253 158.961 41.6677 159.815 41.6677 160.868C41.6677 161.921 42.5253 162.774 43.5831 162.774Z'
            fill='white'
          />
          <path
            d='M50.5389 162.774C51.5967 162.774 52.4543 161.921 52.4543 160.868C52.4543 159.815 51.5967 158.961 50.5389 158.961C49.4811 158.961 48.6235 159.815 48.6235 160.868C48.6235 161.921 49.4811 162.774 50.5389 162.774Z'
            fill='white'
          />
          <path
            d='M57.4947 162.774C58.5525 162.774 59.4101 161.921 59.4101 160.868C59.4101 159.815 58.5525 158.961 57.4947 158.961C56.4369 158.961 55.5793 159.815 55.5793 160.868C55.5793 161.921 56.4369 162.774 57.4947 162.774Z'
            fill='white'
          />
          <path
            d='M64.417 162.774C65.4748 162.774 66.3323 161.921 66.3323 160.868C66.3323 159.815 65.4748 158.961 64.417 158.961C63.3591 158.961 62.5016 159.815 62.5016 160.868C62.5016 161.921 63.3591 162.774 64.417 162.774Z'
            fill='white'
          />
          <path
            d='M71.3728 162.774C72.4306 162.774 73.2881 161.921 73.2881 160.868C73.2881 159.815 72.4306 158.961 71.3728 158.961C70.3149 158.961 69.4574 159.815 69.4574 160.868C69.4574 161.921 70.3149 162.774 71.3728 162.774Z'
            fill='white'
          />
          <path
            d='M78.2949 162.774C79.3528 162.774 80.2103 161.921 80.2103 160.868C80.2103 159.815 79.3528 158.961 78.2949 158.961C77.2371 158.961 76.3796 159.815 76.3796 160.868C76.3796 161.921 77.2371 162.774 78.2949 162.774Z'
            fill='white'
          />
          <path
            d='M1.91537 169.697C2.9732 169.697 3.83074 168.843 3.83074 167.791C3.83074 166.738 2.9732 165.884 1.91537 165.884C0.85754 165.884 0 166.738 0 167.791C0 168.843 0.85754 169.697 1.91537 169.697Z'
            fill='white'
          />
          <path
            d='M8.87118 169.697C9.92901 169.697 10.7866 168.843 10.7866 167.791C10.7866 166.738 9.92901 165.884 8.87118 165.884C7.81335 165.884 6.95581 166.738 6.95581 167.791C6.95581 168.843 7.81335 169.697 8.87118 169.697Z'
            fill='white'
          />
          <path
            d='M15.827 169.697C16.8848 169.697 17.7424 168.843 17.7424 167.791C17.7424 166.738 16.8848 165.884 15.827 165.884C14.7692 165.884 13.9116 166.738 13.9116 167.791C13.9116 168.843 14.7692 169.697 15.827 169.697Z'
            fill='white'
          />
          <path
            d='M22.7492 169.697C23.8071 169.697 24.6646 168.843 24.6646 167.791C24.6646 166.738 23.8071 165.884 22.7492 165.884C21.6914 165.884 20.8339 166.738 20.8339 167.791C20.8339 168.843 21.6914 169.697 22.7492 169.697Z'
            fill='white'
          />
          <path
            d='M29.705 169.697C30.7629 169.697 31.6204 168.843 31.6204 167.791C31.6204 166.738 30.7629 165.884 29.705 165.884C28.6472 165.884 27.7897 166.738 27.7897 167.791C27.7897 168.843 28.6472 169.697 29.705 169.697Z'
            fill='white'
          />
          <path
            d='M36.6609 169.697C37.7187 169.697 38.5762 168.843 38.5762 167.791C38.5762 166.738 37.7187 165.884 36.6609 165.884C35.603 165.884 34.7455 166.738 34.7455 167.791C34.7455 168.843 35.603 169.697 36.6609 169.697Z'
            fill='white'
          />
          <path
            d='M43.5831 169.697C44.6409 169.697 45.4985 168.843 45.4985 167.791C45.4985 166.738 44.6409 165.884 43.5831 165.884C42.5253 165.884 41.6677 166.738 41.6677 167.791C41.6677 168.843 42.5253 169.697 43.5831 169.697Z'
            fill='white'
          />
          <path
            d='M50.5389 169.697C51.5967 169.697 52.4543 168.843 52.4543 167.791C52.4543 166.738 51.5967 165.884 50.5389 165.884C49.4811 165.884 48.6235 166.738 48.6235 167.791C48.6235 168.843 49.4811 169.697 50.5389 169.697Z'
            fill='white'
          />
          <path
            d='M57.4947 169.697C58.5525 169.697 59.4101 168.843 59.4101 167.791C59.4101 166.738 58.5525 165.884 57.4947 165.884C56.4369 165.884 55.5793 166.738 55.5793 167.791C55.5793 168.843 56.4369 169.697 57.4947 169.697Z'
            fill='white'
          />
          <path
            d='M64.417 169.697C65.4748 169.697 66.3323 168.843 66.3323 167.791C66.3323 166.738 65.4748 165.884 64.417 165.884C63.3591 165.884 62.5016 166.738 62.5016 167.791C62.5016 168.843 63.3591 169.697 64.417 169.697Z'
            fill='white'
          />
          <path
            d='M71.3728 169.697C72.4306 169.697 73.2881 168.843 73.2881 167.791C73.2881 166.738 72.4306 165.884 71.3728 165.884C70.3149 165.884 69.4574 166.738 69.4574 167.791C69.4574 168.843 70.3149 169.697 71.3728 169.697Z'
            fill='white'
          />
          <path
            d='M1.91537 176.587C2.9732 176.587 3.83074 175.733 3.83074 174.68C3.83074 173.627 2.9732 172.774 1.91537 172.774C0.85754 172.774 0 173.627 0 174.68C0 175.733 0.85754 176.587 1.91537 176.587Z'
            fill='white'
          />
          <path
            d='M8.87118 176.587C9.92901 176.587 10.7866 175.733 10.7866 174.68C10.7866 173.627 9.92901 172.774 8.87118 172.774C7.81335 172.774 6.95581 173.627 6.95581 174.68C6.95581 175.733 7.81335 176.587 8.87118 176.587Z'
            fill='white'
          />
          <path
            d='M15.827 176.587C16.8848 176.587 17.7424 175.733 17.7424 174.68C17.7424 173.627 16.8848 172.774 15.827 172.774C14.7692 172.774 13.9116 173.627 13.9116 174.68C13.9116 175.733 14.7692 176.587 15.827 176.587Z'
            fill='white'
          />
          <path
            d='M22.7492 176.587C23.8071 176.587 24.6646 175.733 24.6646 174.68C24.6646 173.627 23.8071 172.774 22.7492 172.774C21.6914 172.774 20.8339 173.627 20.8339 174.68C20.8339 175.733 21.6914 176.587 22.7492 176.587Z'
            fill='white'
          />
          <path
            d='M29.705 176.587C30.7629 176.587 31.6204 175.733 31.6204 174.68C31.6204 173.627 30.7629 172.774 29.705 172.774C28.6472 172.774 27.7897 173.627 27.7897 174.68C27.7897 175.733 28.6472 176.587 29.705 176.587Z'
            fill='white'
          />
          <path
            d='M36.6609 176.587C37.7187 176.587 38.5762 175.733 38.5762 174.68C38.5762 173.627 37.7187 172.774 36.6609 172.774C35.603 172.774 34.7455 173.627 34.7455 174.68C34.7455 175.733 35.603 176.587 36.6609 176.587Z'
            fill='white'
          />
          <path
            d='M43.5831 176.587C44.6409 176.587 45.4985 175.733 45.4985 174.68C45.4985 173.627 44.6409 172.774 43.5831 172.774C42.5253 172.774 41.6677 173.627 41.6677 174.68C41.6677 175.733 42.5253 176.587 43.5831 176.587Z'
            fill='white'
          />
          <path
            d='M50.5389 176.587C51.5967 176.587 52.4543 175.733 52.4543 174.68C52.4543 173.627 51.5967 172.774 50.5389 172.774C49.4811 172.774 48.6235 173.627 48.6235 174.68C48.6235 175.733 49.4811 176.587 50.5389 176.587Z'
            fill='white'
          />
          <path
            d='M57.4947 176.587C58.5525 176.587 59.4101 175.733 59.4101 174.68C59.4101 173.627 58.5525 172.774 57.4947 172.774C56.4369 172.774 55.5793 173.627 55.5793 174.68C55.5793 175.733 56.4369 176.587 57.4947 176.587Z'
            fill='white'
          />
          <path
            d='M64.417 176.587C65.4748 176.587 66.3323 175.733 66.3323 174.68C66.3323 173.627 65.4748 172.774 64.417 172.774C63.3591 172.774 62.5016 173.627 62.5016 174.68C62.5016 175.733 63.3591 176.587 64.417 176.587Z'
            fill='white'
          />
          <path
            d='M71.3728 176.587C72.4306 176.587 73.2881 175.733 73.2881 174.68C73.2881 173.627 72.4306 172.774 71.3728 172.774C70.3149 172.774 69.4574 173.627 69.4574 174.68C69.4574 175.733 70.3149 176.587 71.3728 176.587Z'
            fill='white'
          />
          <path
            d='M1.91537 183.509C2.9732 183.509 3.83074 182.656 3.83074 181.603C3.83074 180.55 2.9732 179.697 1.91537 179.697C0.85754 179.697 0 180.55 0 181.603C0 182.656 0.85754 183.509 1.91537 183.509Z'
            fill='white'
          />
          <path
            d='M8.87118 183.509C9.92901 183.509 10.7866 182.656 10.7866 181.603C10.7866 180.55 9.92901 179.697 8.87118 179.697C7.81335 179.697 6.95581 180.55 6.95581 181.603C6.95581 182.656 7.81335 183.509 8.87118 183.509Z'
            fill='white'
          />
          <path
            d='M15.827 183.509C16.8848 183.509 17.7424 182.656 17.7424 181.603C17.7424 180.55 16.8848 179.697 15.827 179.697C14.7692 179.697 13.9116 180.55 13.9116 181.603C13.9116 182.656 14.7692 183.509 15.827 183.509Z'
            fill='white'
          />
          <path
            d='M22.7492 183.509C23.8071 183.509 24.6646 182.656 24.6646 181.603C24.6646 180.55 23.8071 179.697 22.7492 179.697C21.6914 179.697 20.8339 180.55 20.8339 181.603C20.8339 182.656 21.6914 183.509 22.7492 183.509Z'
            fill='white'
          />
          <path
            d='M29.705 183.509C30.7629 183.509 31.6204 182.656 31.6204 181.603C31.6204 180.55 30.7629 179.697 29.705 179.697C28.6472 179.697 27.7897 180.55 27.7897 181.603C27.7897 182.656 28.6472 183.509 29.705 183.509Z'
            fill='white'
          />
          <path
            d='M36.6609 183.509C37.7187 183.509 38.5762 182.656 38.5762 181.603C38.5762 180.55 37.7187 179.697 36.6609 179.697C35.603 179.697 34.7455 180.55 34.7455 181.603C34.7455 182.656 35.603 183.509 36.6609 183.509Z'
            fill='white'
          />
          <path
            d='M43.5831 183.509C44.6409 183.509 45.4985 182.656 45.4985 181.603C45.4985 180.55 44.6409 179.697 43.5831 179.697C42.5253 179.697 41.6677 180.55 41.6677 181.603C41.6677 182.656 42.5253 183.509 43.5831 183.509Z'
            fill='white'
          />
          <path
            d='M50.5389 183.509C51.5967 183.509 52.4543 182.656 52.4543 181.603C52.4543 180.55 51.5967 179.697 50.5389 179.697C49.4811 179.697 48.6235 180.55 48.6235 181.603C48.6235 182.656 49.4811 183.509 50.5389 183.509Z'
            fill='white'
          />
          <path
            d='M57.4947 183.509C58.5525 183.509 59.4101 182.656 59.4101 181.603C59.4101 180.55 58.5525 179.697 57.4947 179.697C56.4369 179.697 55.5793 180.55 55.5793 181.603C55.5793 182.656 56.4369 183.509 57.4947 183.509Z'
            fill='white'
          />
          <path
            d='M64.417 183.509C65.4748 183.509 66.3323 182.656 66.3323 181.603C66.3323 180.55 65.4748 179.697 64.417 179.697C63.3591 179.697 62.5016 180.55 62.5016 181.603C62.5016 182.656 63.3591 183.509 64.417 183.509Z'
            fill='white'
          />
          <path
            d='M71.3728 183.509C72.4306 183.509 73.2881 182.656 73.2881 181.603C73.2881 180.55 72.4306 179.697 71.3728 179.697C70.3149 179.697 69.4574 180.55 69.4574 181.603C69.4574 182.656 70.3149 183.509 71.3728 183.509Z'
            fill='white'
          />
          <path
            d='M78.2949 183.509C79.3528 183.509 80.2103 182.656 80.2103 181.603C80.2103 180.55 79.3528 179.697 78.2949 179.697C77.2371 179.697 76.3796 180.55 76.3796 181.603C76.3796 182.656 77.2371 183.509 78.2949 183.509Z'
            fill='white'
          />
          <path
            d='M8.87118 190.433C9.92901 190.433 10.7866 189.579 10.7866 188.526C10.7866 187.474 9.92901 186.62 8.87118 186.62C7.81335 186.62 6.95581 187.474 6.95581 188.526C6.95581 189.579 7.81335 190.433 8.87118 190.433Z'
            fill='white'
          />
          <path
            d='M15.827 190.433C16.8848 190.433 17.7424 189.579 17.7424 188.526C17.7424 187.474 16.8848 186.62 15.827 186.62C14.7692 186.62 13.9116 187.474 13.9116 188.526C13.9116 189.579 14.7692 190.433 15.827 190.433Z'
            fill='white'
          />
          <path
            d='M22.7492 190.433C23.8071 190.433 24.6646 189.579 24.6646 188.526C24.6646 187.474 23.8071 186.62 22.7492 186.62C21.6914 186.62 20.8339 187.474 20.8339 188.526C20.8339 189.579 21.6914 190.433 22.7492 190.433Z'
            fill='white'
          />
          <path
            d='M29.705 190.433C30.7629 190.433 31.6204 189.579 31.6204 188.526C31.6204 187.474 30.7629 186.62 29.705 186.62C28.6472 186.62 27.7897 187.474 27.7897 188.526C27.7897 189.579 28.6472 190.433 29.705 190.433Z'
            fill='white'
          />
          <path
            d='M36.6609 190.433C37.7187 190.433 38.5762 189.579 38.5762 188.526C38.5762 187.474 37.7187 186.62 36.6609 186.62C35.603 186.62 34.7455 187.474 34.7455 188.526C34.7455 189.579 35.603 190.433 36.6609 190.433Z'
            fill='white'
          />
          <path
            d='M43.5831 190.433C44.6409 190.433 45.4985 189.579 45.4985 188.526C45.4985 187.474 44.6409 186.62 43.5831 186.62C42.5253 186.62 41.6677 187.474 41.6677 188.526C41.6677 189.579 42.5253 190.433 43.5831 190.433Z'
            fill='white'
          />
          <path
            d='M50.5389 190.433C51.5967 190.433 52.4543 189.579 52.4543 188.526C52.4543 187.474 51.5967 186.62 50.5389 186.62C49.4811 186.62 48.6235 187.474 48.6235 188.526C48.6235 189.579 49.4811 190.433 50.5389 190.433Z'
            fill='white'
          />
          <path
            d='M57.4947 190.433C58.5525 190.433 59.4101 189.579 59.4101 188.526C59.4101 187.474 58.5525 186.62 57.4947 186.62C56.4369 186.62 55.5793 187.474 55.5793 188.526C55.5793 189.579 56.4369 190.433 57.4947 190.433Z'
            fill='white'
          />
          <path
            d='M64.417 190.433C65.4748 190.433 66.3323 189.579 66.3323 188.526C66.3323 187.474 65.4748 186.62 64.417 186.62C63.3591 186.62 62.5016 187.474 62.5016 188.526C62.5016 189.579 63.3591 190.433 64.417 190.433Z'
            fill='white'
          />
          <path
            d='M71.3728 190.433C72.4306 190.433 73.2881 189.579 73.2881 188.526C73.2881 187.474 72.4306 186.62 71.3728 186.62C70.3149 186.62 69.4574 187.474 69.4574 188.526C69.4574 189.579 70.3149 190.433 71.3728 190.433Z'
            fill='white'
          />
          <path
            d='M8.87118 197.322C9.92901 197.322 10.7866 196.469 10.7866 195.416C10.7866 194.363 9.92901 193.51 8.87118 193.51C7.81335 193.51 6.95581 194.363 6.95581 195.416C6.95581 196.469 7.81335 197.322 8.87118 197.322Z'
            fill='white'
          />
          <path
            d='M15.827 197.322C16.8848 197.322 17.7424 196.469 17.7424 195.416C17.7424 194.363 16.8848 193.51 15.827 193.51C14.7692 193.51 13.9116 194.363 13.9116 195.416C13.9116 196.469 14.7692 197.322 15.827 197.322Z'
            fill='white'
          />
          <path
            d='M22.7492 197.322C23.8071 197.322 24.6646 196.469 24.6646 195.416C24.6646 194.363 23.8071 193.51 22.7492 193.51C21.6914 193.51 20.8339 194.363 20.8339 195.416C20.8339 196.469 21.6914 197.322 22.7492 197.322Z'
            fill='white'
          />
          <path
            d='M29.705 197.322C30.7629 197.322 31.6204 196.469 31.6204 195.416C31.6204 194.363 30.7629 193.51 29.705 193.51C28.6472 193.51 27.7897 194.363 27.7897 195.416C27.7897 196.469 28.6472 197.322 29.705 197.322Z'
            fill='white'
          />
          <path
            d='M36.6609 197.322C37.7187 197.322 38.5762 196.469 38.5762 195.416C38.5762 194.363 37.7187 193.51 36.6609 193.51C35.603 193.51 34.7455 194.363 34.7455 195.416C34.7455 196.469 35.603 197.322 36.6609 197.322Z'
            fill='white'
          />
          <path
            d='M43.5831 197.322C44.6409 197.322 45.4985 196.469 45.4985 195.416C45.4985 194.363 44.6409 193.51 43.5831 193.51C42.5253 193.51 41.6677 194.363 41.6677 195.416C41.6677 196.469 42.5253 197.322 43.5831 197.322Z'
            fill='white'
          />
          <path
            d='M50.5389 197.322C51.5967 197.322 52.4543 196.469 52.4543 195.416C52.4543 194.363 51.5967 193.51 50.5389 193.51C49.4811 193.51 48.6235 194.363 48.6235 195.416C48.6235 196.469 49.4811 197.322 50.5389 197.322Z'
            fill='white'
          />
          <path
            d='M57.4947 197.322C58.5525 197.322 59.4101 196.469 59.4101 195.416C59.4101 194.363 58.5525 193.51 57.4947 193.51C56.4369 193.51 55.5793 194.363 55.5793 195.416C55.5793 196.469 56.4369 197.322 57.4947 197.322Z'
            fill='white'
          />
          <path
            d='M64.417 197.322C65.4748 197.322 66.3323 196.469 66.3323 195.416C66.3323 194.363 65.4748 193.51 64.417 193.51C63.3591 193.51 62.5016 194.363 62.5016 195.416C62.5016 196.469 63.3591 197.322 64.417 197.322Z'
            fill='white'
          />
          <path
            d='M71.3728 197.322C72.4306 197.322 73.2881 196.469 73.2881 195.416C73.2881 194.363 72.4306 193.51 71.3728 193.51C70.3149 193.51 69.4574 194.363 69.4574 195.416C69.4574 196.469 70.3149 197.322 71.3728 197.322Z'
            fill='white'
          />
          <path
            d='M8.87118 204.245C9.92901 204.245 10.7866 203.392 10.7866 202.339C10.7866 201.286 9.92901 200.433 8.87118 200.433C7.81335 200.433 6.95581 201.286 6.95581 202.339C6.95581 203.392 7.81335 204.245 8.87118 204.245Z'
            fill='white'
          />
          <path
            d='M15.827 204.245C16.8848 204.245 17.7424 203.392 17.7424 202.339C17.7424 201.286 16.8848 200.433 15.827 200.433C14.7692 200.433 13.9116 201.286 13.9116 202.339C13.9116 203.392 14.7692 204.245 15.827 204.245Z'
            fill='white'
          />
          <path
            d='M22.7492 204.245C23.8071 204.245 24.6646 203.392 24.6646 202.339C24.6646 201.286 23.8071 200.433 22.7492 200.433C21.6914 200.433 20.8339 201.286 20.8339 202.339C20.8339 203.392 21.6914 204.245 22.7492 204.245Z'
            fill='white'
          />
          <path
            d='M29.705 204.245C30.7629 204.245 31.6204 203.392 31.6204 202.339C31.6204 201.286 30.7629 200.433 29.705 200.433C28.6472 200.433 27.7897 201.286 27.7897 202.339C27.7897 203.392 28.6472 204.245 29.705 204.245Z'
            fill='white'
          />
          <path
            d='M36.6609 204.245C37.7187 204.245 38.5762 203.392 38.5762 202.339C38.5762 201.286 37.7187 200.433 36.6609 200.433C35.603 200.433 34.7455 201.286 34.7455 202.339C34.7455 203.392 35.603 204.245 36.6609 204.245Z'
            fill='white'
          />
          <path
            d='M43.5831 204.245C44.6409 204.245 45.4985 203.392 45.4985 202.339C45.4985 201.286 44.6409 200.433 43.5831 200.433C42.5253 200.433 41.6677 201.286 41.6677 202.339C41.6677 203.392 42.5253 204.245 43.5831 204.245Z'
            fill='white'
          />
          <path
            d='M50.5389 204.245C51.5967 204.245 52.4543 203.392 52.4543 202.339C52.4543 201.286 51.5967 200.433 50.5389 200.433C49.4811 200.433 48.6235 201.286 48.6235 202.339C48.6235 203.392 49.4811 204.245 50.5389 204.245Z'
            fill='white'
          />
          <path
            d='M57.4947 204.245C58.5525 204.245 59.4101 203.392 59.4101 202.339C59.4101 201.286 58.5525 200.433 57.4947 200.433C56.4369 200.433 55.5793 201.286 55.5793 202.339C55.5793 203.392 56.4369 204.245 57.4947 204.245Z'
            fill='white'
          />
          <path
            d='M64.417 204.245C65.4748 204.245 66.3323 203.392 66.3323 202.339C66.3323 201.286 65.4748 200.433 64.417 200.433C63.3591 200.433 62.5016 201.286 62.5016 202.339C62.5016 203.392 63.3591 204.245 64.417 204.245Z'
            fill='white'
          />
          <path
            d='M15.827 211.135C16.8848 211.135 17.7424 210.281 17.7424 209.229C17.7424 208.176 16.8848 207.322 15.827 207.322C14.7692 207.322 13.9116 208.176 13.9116 209.229C13.9116 210.281 14.7692 211.135 15.827 211.135Z'
            fill='white'
          />
          <path
            d='M22.7492 211.135C23.8071 211.135 24.6646 210.281 24.6646 209.229C24.6646 208.176 23.8071 207.322 22.7492 207.322C21.6914 207.322 20.8339 208.176 20.8339 209.229C20.8339 210.281 21.6914 211.135 22.7492 211.135Z'
            fill='white'
          />
          <path
            d='M29.705 211.135C30.7629 211.135 31.6204 210.281 31.6204 209.229C31.6204 208.176 30.7629 207.322 29.705 207.322C28.6472 207.322 27.7897 208.176 27.7897 209.229C27.7897 210.281 28.6472 211.135 29.705 211.135Z'
            fill='white'
          />
          <path
            d='M36.6609 211.135C37.7187 211.135 38.5762 210.281 38.5762 209.229C38.5762 208.176 37.7187 207.322 36.6609 207.322C35.603 207.322 34.7455 208.176 34.7455 209.229C34.7455 210.281 35.603 211.135 36.6609 211.135Z'
            fill='white'
          />
          <path
            d='M43.5831 211.135C44.6409 211.135 45.4985 210.281 45.4985 209.229C45.4985 208.176 44.6409 207.322 43.5831 207.322C42.5253 207.322 41.6677 208.176 41.6677 209.229C41.6677 210.281 42.5253 211.135 43.5831 211.135Z'
            fill='white'
          />
          <path
            d='M50.5389 211.135C51.5967 211.135 52.4543 210.281 52.4543 209.229C52.4543 208.176 51.5967 207.322 50.5389 207.322C49.4811 207.322 48.6235 208.176 48.6235 209.229C48.6235 210.281 49.4811 211.135 50.5389 211.135Z'
            fill='white'
          />
          <path
            d='M57.4947 211.135C58.5525 211.135 59.4101 210.281 59.4101 209.229C59.4101 208.176 58.5525 207.322 57.4947 207.322C56.4369 207.322 55.5793 208.176 55.5793 209.229C55.5793 210.281 56.4369 211.135 57.4947 211.135Z'
            fill='white'
          />
          <path
            d='M64.417 211.135C65.4748 211.135 66.3323 210.281 66.3323 209.229C66.3323 208.176 65.4748 207.322 64.417 207.322C63.3591 207.322 62.5016 208.176 62.5016 209.229C62.5016 210.281 63.3591 211.135 64.417 211.135Z'
            fill='white'
          />
          <path
            d='M15.827 218.058C16.8848 218.058 17.7424 217.204 17.7424 216.151C17.7424 215.099 16.8848 214.245 15.827 214.245C14.7692 214.245 13.9116 215.099 13.9116 216.151C13.9116 217.204 14.7692 218.058 15.827 218.058Z'
            fill='white'
          />
          <path
            d='M22.7492 218.058C23.8071 218.058 24.6646 217.204 24.6646 216.151C24.6646 215.099 23.8071 214.245 22.7492 214.245C21.6914 214.245 20.8339 215.099 20.8339 216.151C20.8339 217.204 21.6914 218.058 22.7492 218.058Z'
            fill='white'
          />
          <path
            d='M29.705 218.058C30.7629 218.058 31.6204 217.204 31.6204 216.151C31.6204 215.099 30.7629 214.245 29.705 214.245C28.6472 214.245 27.7897 215.099 27.7897 216.151C27.7897 217.204 28.6472 218.058 29.705 218.058Z'
            fill='white'
          />
          <path
            d='M36.6609 218.058C37.7187 218.058 38.5762 217.204 38.5762 216.151C38.5762 215.099 37.7187 214.245 36.6609 214.245C35.603 214.245 34.7455 215.099 34.7455 216.151C34.7455 217.204 35.603 218.058 36.6609 218.058Z'
            fill='white'
          />
          <path
            d='M43.5831 218.058C44.6409 218.058 45.4985 217.204 45.4985 216.151C45.4985 215.099 44.6409 214.245 43.5831 214.245C42.5253 214.245 41.6677 215.099 41.6677 216.151C41.6677 217.204 42.5253 218.058 43.5831 218.058Z'
            fill='white'
          />
          <path
            d='M50.5389 218.058C51.5967 218.058 52.4543 217.204 52.4543 216.151C52.4543 215.099 51.5967 214.245 50.5389 214.245C49.4811 214.245 48.6235 215.099 48.6235 216.151C48.6235 217.204 49.4811 218.058 50.5389 218.058Z'
            fill='white'
          />
          <path
            d='M57.4947 218.058C58.5525 218.058 59.4101 217.204 59.4101 216.151C59.4101 215.099 58.5525 214.245 57.4947 214.245C56.4369 214.245 55.5793 215.099 55.5793 216.151C55.5793 217.204 56.4369 218.058 57.4947 218.058Z'
            fill='white'
          />
          <path
            d='M64.417 218.058C65.4748 218.058 66.3323 217.204 66.3323 216.151C66.3323 215.099 65.4748 214.245 64.417 214.245C63.3591 214.245 62.5016 215.099 62.5016 216.151C62.5016 217.204 63.3591 218.058 64.417 218.058Z'
            fill='white'
          />
          <path
            d='M15.827 224.981C16.8848 224.981 17.7424 224.127 17.7424 223.074C17.7424 222.021 16.8848 221.168 15.827 221.168C14.7692 221.168 13.9116 222.021 13.9116 223.074C13.9116 224.127 14.7692 224.981 15.827 224.981Z'
            fill='white'
          />
          <path
            d='M22.7492 224.981C23.8071 224.981 24.6646 224.127 24.6646 223.074C24.6646 222.021 23.8071 221.168 22.7492 221.168C21.6914 221.168 20.8339 222.021 20.8339 223.074C20.8339 224.127 21.6914 224.981 22.7492 224.981Z'
            fill='white'
          />
          <path
            d='M29.705 224.981C30.7629 224.981 31.6204 224.127 31.6204 223.074C31.6204 222.021 30.7629 221.168 29.705 221.168C28.6472 221.168 27.7897 222.021 27.7897 223.074C27.7897 224.127 28.6472 224.981 29.705 224.981Z'
            fill='white'
          />
          <path
            d='M36.6609 224.981C37.7187 224.981 38.5762 224.127 38.5762 223.074C38.5762 222.021 37.7187 221.168 36.6609 221.168C35.603 221.168 34.7455 222.021 34.7455 223.074C34.7455 224.127 35.603 224.981 36.6609 224.981Z'
            fill='white'
          />
          <path
            d='M43.5831 224.981C44.6409 224.981 45.4985 224.127 45.4985 223.074C45.4985 222.021 44.6409 221.168 43.5831 221.168C42.5253 221.168 41.6677 222.021 41.6677 223.074C41.6677 224.127 42.5253 224.981 43.5831 224.981Z'
            fill='white'
          />
          <path
            d='M50.5389 224.981C51.5967 224.981 52.4543 224.127 52.4543 223.074C52.4543 222.021 51.5967 221.168 50.5389 221.168C49.4811 221.168 48.6235 222.021 48.6235 223.074C48.6235 224.127 49.4811 224.981 50.5389 224.981Z'
            fill='white'
          />
          <path
            d='M57.4947 224.981C58.5525 224.981 59.4101 224.127 59.4101 223.074C59.4101 222.021 58.5525 221.168 57.4947 221.168C56.4369 221.168 55.5793 222.021 55.5793 223.074C55.5793 224.127 56.4369 224.981 57.4947 224.981Z'
            fill='white'
          />
          <path
            d='M22.7492 231.87C23.8071 231.87 24.6646 231.017 24.6646 229.964C24.6646 228.911 23.8071 228.058 22.7492 228.058C21.6914 228.058 20.8339 228.911 20.8339 229.964C20.8339 231.017 21.6914 231.87 22.7492 231.87Z'
            fill='white'
          />
          <path
            d='M29.705 231.87C30.7629 231.87 31.6204 231.017 31.6204 229.964C31.6204 228.911 30.7629 228.058 29.705 228.058C28.6472 228.058 27.7897 228.911 27.7897 229.964C27.7897 231.017 28.6472 231.87 29.705 231.87Z'
            fill='white'
          />
          <path
            d='M36.6609 231.87C37.7187 231.87 38.5762 231.017 38.5762 229.964C38.5762 228.911 37.7187 228.058 36.6609 228.058C35.603 228.058 34.7455 228.911 34.7455 229.964C34.7455 231.017 35.603 231.87 36.6609 231.87Z'
            fill='white'
          />
          <path
            d='M43.5831 231.87C44.6409 231.87 45.4985 231.017 45.4985 229.964C45.4985 228.911 44.6409 228.058 43.5831 228.058C42.5253 228.058 41.6677 228.911 41.6677 229.964C41.6677 231.017 42.5253 231.87 43.5831 231.87Z'
            fill='white'
          />
          <path
            d='M50.5389 231.87C51.5967 231.87 52.4543 231.017 52.4543 229.964C52.4543 228.911 51.5967 228.058 50.5389 228.058C49.4811 228.058 48.6235 228.911 48.6235 229.964C48.6235 231.017 49.4811 231.87 50.5389 231.87Z'
            fill='white'
          />
          <path
            d='M57.4947 231.87C58.5525 231.87 59.4101 231.017 59.4101 229.964C59.4101 228.911 58.5525 228.058 57.4947 228.058C56.4369 228.058 55.5793 228.911 55.5793 229.964C55.5793 231.017 56.4369 231.87 57.4947 231.87Z'
            fill='white'
          />
          <path
            d='M22.7492 238.793C23.8071 238.793 24.6646 237.94 24.6646 236.887C24.6646 235.834 23.8071 234.98 22.7492 234.98C21.6914 234.98 20.8339 235.834 20.8339 236.887C20.8339 237.94 21.6914 238.793 22.7492 238.793Z'
            fill='white'
          />
          <path
            d='M29.705 238.793C30.7629 238.793 31.6204 237.94 31.6204 236.887C31.6204 235.834 30.7629 234.98 29.705 234.98C28.6472 234.98 27.7897 235.834 27.7897 236.887C27.7897 237.94 28.6472 238.793 29.705 238.793Z'
            fill='white'
          />
          <path
            d='M36.6609 238.793C37.7187 238.793 38.5762 237.94 38.5762 236.887C38.5762 235.834 37.7187 234.98 36.6609 234.98C35.603 234.98 34.7455 235.834 34.7455 236.887C34.7455 237.94 35.603 238.793 36.6609 238.793Z'
            fill='white'
          />
          <path
            d='M43.5831 238.793C44.6409 238.793 45.4985 237.94 45.4985 236.887C45.4985 235.834 44.6409 234.98 43.5831 234.98C42.5253 234.98 41.6677 235.834 41.6677 236.887C41.6677 237.94 42.5253 238.793 43.5831 238.793Z'
            fill='white'
          />
          <path
            d='M50.5389 238.793C51.5967 238.793 52.4543 237.94 52.4543 236.887C52.4543 235.834 51.5967 234.98 50.5389 234.98C49.4811 234.98 48.6235 235.834 48.6235 236.887C48.6235 237.94 49.4811 238.793 50.5389 238.793Z'
            fill='white'
          />
          <path
            d='M57.4947 238.793C58.5525 238.793 59.4101 237.94 59.4101 236.887C59.4101 235.834 58.5525 234.98 57.4947 234.98C56.4369 234.98 55.5793 235.834 55.5793 236.887C55.5793 237.94 56.4369 238.793 57.4947 238.793Z'
            fill='white'
          />
          <path
            d='M22.7492 245.716C23.8071 245.716 24.6646 244.863 24.6646 243.81C24.6646 242.757 23.8071 241.904 22.7492 241.904C21.6914 241.904 20.8339 242.757 20.8339 243.81C20.8339 244.863 21.6914 245.716 22.7492 245.716Z'
            fill='white'
          />
          <path
            d='M29.705 245.716C30.7629 245.716 31.6204 244.863 31.6204 243.81C31.6204 242.757 30.7629 241.904 29.705 241.904C28.6472 241.904 27.7897 242.757 27.7897 243.81C27.7897 244.863 28.6472 245.716 29.705 245.716Z'
            fill='white'
          />
          <path
            d='M36.6609 245.716C37.7187 245.716 38.5762 244.863 38.5762 243.81C38.5762 242.757 37.7187 241.904 36.6609 241.904C35.603 241.904 34.7455 242.757 34.7455 243.81C34.7455 244.863 35.603 245.716 36.6609 245.716Z'
            fill='white'
          />
          <path
            d='M43.5831 245.716C44.6409 245.716 45.4985 244.863 45.4985 243.81C45.4985 242.757 44.6409 241.904 43.5831 241.904C42.5253 241.904 41.6677 242.757 41.6677 243.81C41.6677 244.863 42.5253 245.716 43.5831 245.716Z'
            fill='white'
          />
          <path
            d='M50.5389 245.716C51.5967 245.716 52.4543 244.863 52.4543 243.81C52.4543 242.757 51.5967 241.904 50.5389 241.904C49.4811 241.904 48.6235 242.757 48.6235 243.81C48.6235 244.863 49.4811 245.716 50.5389 245.716Z'
            fill='white'
          />
          <path
            d='M57.4947 245.716C58.5525 245.716 59.4101 244.863 59.4101 243.81C59.4101 242.757 58.5525 241.904 57.4947 241.904C56.4369 241.904 55.5793 242.757 55.5793 243.81C55.5793 244.863 56.4369 245.716 57.4947 245.716Z'
            fill='white'
          />
          <path
            d='M29.705 252.606C30.7629 252.606 31.6204 251.752 31.6204 250.699C31.6204 249.646 30.7629 248.793 29.705 248.793C28.6472 248.793 27.7897 249.646 27.7897 250.699C27.7897 251.752 28.6472 252.606 29.705 252.606Z'
            fill='white'
          />
          <path
            d='M36.6609 252.606C37.7187 252.606 38.5762 251.752 38.5762 250.699C38.5762 249.646 37.7187 248.793 36.6609 248.793C35.603 248.793 34.7455 249.646 34.7455 250.699C34.7455 251.752 35.603 252.606 36.6609 252.606Z'
            fill='white'
          />
          <path
            d='M43.5831 252.606C44.6409 252.606 45.4985 251.752 45.4985 250.699C45.4985 249.646 44.6409 248.793 43.5831 248.793C42.5253 248.793 41.6677 249.646 41.6677 250.699C41.6677 251.752 42.5253 252.606 43.5831 252.606Z'
            fill='white'
          />
          <path
            d='M50.5389 252.606C51.5967 252.606 52.4543 251.752 52.4543 250.699C52.4543 249.646 51.5967 248.793 50.5389 248.793C49.4811 248.793 48.6235 249.646 48.6235 250.699C48.6235 251.752 49.4811 252.606 50.5389 252.606Z'
            fill='white'
          />
          <path
            d='M57.4947 252.606C58.5525 252.606 59.4101 251.752 59.4101 250.699C59.4101 249.646 58.5525 248.793 57.4947 248.793C56.4369 248.793 55.5793 249.646 55.5793 250.699C55.5793 251.752 56.4369 252.606 57.4947 252.606Z'
            fill='white'
          />
          <path
            d='M29.705 259.529C30.7629 259.529 31.6204 258.675 31.6204 257.623C31.6204 256.57 30.7629 255.716 29.705 255.716C28.6472 255.716 27.7897 256.57 27.7897 257.623C27.7897 258.675 28.6472 259.529 29.705 259.529Z'
            fill='white'
          />
          <path
            d='M36.6609 259.529C37.7187 259.529 38.5762 258.675 38.5762 257.623C38.5762 256.57 37.7187 255.716 36.6609 255.716C35.603 255.716 34.7455 256.57 34.7455 257.623C34.7455 258.675 35.603 259.529 36.6609 259.529Z'
            fill='white'
          />
          <path
            d='M43.5831 259.529C44.6409 259.529 45.4985 258.675 45.4985 257.623C45.4985 256.57 44.6409 255.716 43.5831 255.716C42.5253 255.716 41.6677 256.57 41.6677 257.623C41.6677 258.675 42.5253 259.529 43.5831 259.529Z'
            fill='white'
          />
          <path
            d='M50.5389 259.529C51.5967 259.529 52.4543 258.675 52.4543 257.623C52.4543 256.57 51.5967 255.716 50.5389 255.716C49.4811 255.716 48.6235 256.57 48.6235 257.623C48.6235 258.675 49.4811 259.529 50.5389 259.529Z'
            fill='white'
          />
          <path
            d='M57.4947 259.529C58.5525 259.529 59.4101 258.675 59.4101 257.623C59.4101 256.57 58.5525 255.716 57.4947 255.716C56.4369 255.716 55.5793 256.57 55.5793 257.623C55.5793 258.675 56.4369 259.529 57.4947 259.529Z'
            fill='white'
          />
          <path
            d='M29.705 266.452C30.7629 266.452 31.6204 265.598 31.6204 264.545C31.6204 263.493 30.7629 262.639 29.705 262.639C28.6472 262.639 27.7897 263.493 27.7897 264.545C27.7897 265.598 28.6472 266.452 29.705 266.452Z'
            fill='white'
          />
          <path
            d='M36.6609 266.452C37.7187 266.452 38.5762 265.598 38.5762 264.545C38.5762 263.493 37.7187 262.639 36.6609 262.639C35.603 262.639 34.7455 263.493 34.7455 264.545C34.7455 265.598 35.603 266.452 36.6609 266.452Z'
            fill='white'
          />
          <path
            d='M43.5831 266.452C44.6409 266.452 45.4985 265.598 45.4985 264.545C45.4985 263.493 44.6409 262.639 43.5831 262.639C42.5253 262.639 41.6677 263.493 41.6677 264.545C41.6677 265.598 42.5253 266.452 43.5831 266.452Z'
            fill='white'
          />
          <path
            d='M50.5389 266.452C51.5967 266.452 52.4543 265.598 52.4543 264.545C52.4543 263.493 51.5967 262.639 50.5389 262.639C49.4811 262.639 48.6235 263.493 48.6235 264.545C48.6235 265.598 49.4811 266.452 50.5389 266.452Z'
            fill='white'
          />
          <path
            d='M36.6609 273.341C37.7187 273.341 38.5762 272.488 38.5762 271.435C38.5762 270.382 37.7187 269.529 36.6609 269.529C35.603 269.529 34.7455 270.382 34.7455 271.435C34.7455 272.488 35.603 273.341 36.6609 273.341Z'
            fill='white'
          />
          <path
            d='M43.5831 273.341C44.6409 273.341 45.4985 272.488 45.4985 271.435C45.4985 270.382 44.6409 269.529 43.5831 269.529C42.5253 269.529 41.6677 270.382 41.6677 271.435C41.6677 272.488 42.5253 273.341 43.5831 273.341Z'
            fill='white'
          />
          <path
            d='M50.5389 273.341C51.5967 273.341 52.4543 272.488 52.4543 271.435C52.4543 270.382 51.5967 269.529 50.5389 269.529C49.4811 269.529 48.6235 270.382 48.6235 271.435C48.6235 272.488 49.4811 273.341 50.5389 273.341Z'
            fill='white'
          />
          <path
            d='M36.6609 280.264C37.7187 280.264 38.5762 279.411 38.5762 278.358C38.5762 277.305 37.7187 276.452 36.6609 276.452C35.603 276.452 34.7455 277.305 34.7455 278.358C34.7455 279.411 35.603 280.264 36.6609 280.264Z'
            fill='white'
          />
          <path
            d='M43.5831 280.264C44.6409 280.264 45.4985 279.411 45.4985 278.358C45.4985 277.305 44.6409 276.452 43.5831 276.452C42.5253 276.452 41.6677 277.305 41.6677 278.358C41.6677 279.411 42.5253 280.264 43.5831 280.264Z'
            fill='white'
          />
          <path
            d='M50.5389 280.264C51.5967 280.264 52.4543 279.411 52.4543 278.358C52.4543 277.305 51.5967 276.452 50.5389 276.452C49.4811 276.452 48.6235 277.305 48.6235 278.358C48.6235 279.411 49.4811 280.264 50.5389 280.264Z'
            fill='white'
          />
          <path
            d='M36.6609 287.154C37.7187 287.154 38.5762 286.3 38.5762 285.248C38.5762 284.195 37.7187 283.341 36.6609 283.341C35.603 283.341 34.7455 284.195 34.7455 285.248C34.7455 286.3 35.603 287.154 36.6609 287.154Z'
            fill='white'
          />
          <path
            d='M43.5831 287.154C44.6409 287.154 45.4985 286.3 45.4985 285.248C45.4985 284.195 44.6409 283.341 43.5831 283.341C42.5253 283.341 41.6677 284.195 41.6677 285.248C41.6677 286.3 42.5253 287.154 43.5831 287.154Z'
            fill='white'
          />
          <path
            d='M50.5389 287.154C51.5967 287.154 52.4543 286.3 52.4543 285.248C52.4543 284.195 51.5967 283.341 50.5389 283.341C49.4811 283.341 48.6235 284.195 48.6235 285.248C48.6235 286.3 49.4811 287.154 50.5389 287.154Z'
            fill='white'
          />
          <path
            d='M43.5831 294.077C44.6409 294.077 45.4985 293.223 45.4985 292.17C45.4985 291.118 44.6409 290.264 43.5831 290.264C42.5253 290.264 41.6677 291.118 41.6677 292.17C41.6677 293.223 42.5253 294.077 43.5831 294.077Z'
            fill='white'
          />
          <path
            d='M43.5831 301C44.6409 301 45.4985 300.147 45.4985 299.094C45.4985 298.041 44.6409 297.188 43.5831 297.188C42.5253 297.188 41.6677 298.041 41.6677 299.094C41.6677 300.147 42.5253 301 43.5831 301Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_97_864'>
            <rect width='108' height='301' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default LoaderAnim1;
